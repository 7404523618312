import React from "react";

export const Card = ({ children, onClick = () => {}, title, content }) => {
  return (
    <div
      className="bg-white border border-gray-200 rounded-lg p-6 mb-12 shadow-md cursor-pointer 
      gap-4 h-[75dvh] md:h-[30dvh]"
      onClick={() => onClick()}
    >
      {children}
      <div>
        <h3 className="text-blue-500 font-semibold text-lg mb-2">
          {title}
        </h3>
        <p className="text-gray-600 leading-relaxed mt-[10%] overflow-y-auto h-4/5">
          {content}
        </p>
      </div>
    </div>
  );
};
