import { useSetState } from "react-use";
import Joyride, { STATUS } from "react-joyride";
import { useEffect } from "react";
import ediLogo from "../../../edi-logo-transparent.png";

export const DesktopWatchTour = () => {
  // State for Joyride -- Tutorial
  const [{ run, steps }, setState] = useSetState({
    run: false,
    steps: [
      {
        content: (
          <div className="flex flex-col">
            <div className="flex text-center items-center justify-center">
              Start your journey with{" "}
              <img className="w-auto h-6" src={ediLogo} alt="Edi" />
            </div>
            Let's make the magic happen! We have divided the video into sections
            for better learning experience.
          </div>
        ),
        placement: "center",
        styles: {
          options: {
            width: 300,
          },
        },
        target: "body",
        title: (
          <div className="font-bold text-3xl mt-4">Welcome to Tutoring!</div>
        ),
      },
      {
        content: <h2>This is video player</h2>,
        target: "#youtube-video-player",
        title: <b>Video Player</b>,
      },
      {
        content: (
          <h2>
            You can choose which section to read now by selecting from the
            timeline.
          </h2>
        ),
        target: "#outline",
        title: <b>Timeline</b>,
      },
      {
        content: (
          <h2>
            In this section, you can choose the style you want the content
            to be.
          </h2>
        ),
        target: "#video-info-container",
        title: <b>Style Chooser</b>,
      },
      {
        content: (
          <h2>
            If you have any questions, feel free to ask in the chatbox. It is
            trained to answer questions based on the video contents.
          </h2>
        ),
        target: "#chatbox",
        title: <b>Chatbox</b>,
        locale: { last: "Done" },
      },
    ],
  });

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setState({ run: false });
    }
  };

  // Check if this is the first visit to the page
  // If it is, show the tutorial
  useEffect(() => {
    const isDesktopWatchFirstVisit = localStorage.getItem(
      "isDesktopWatchFirstVisit",
    );
    if (!isDesktopWatchFirstVisit) {
      localStorage.setItem("isDesktopWatchFirstVisit", "true");
      setState({ run: true });
    }
  }, []);

  return (
    <Joyride
      callback={handleJoyrideCallback}
      continuous
      run={run}
      scrollToFirstStep
      showSkipButton
      steps={steps}
      styles={{
        options: {
          primaryColor: "#1976d2",
          zIndex: 1000,
        },
      }}
    />
  );
};
