import React, { useEffect, useRef, useState } from "react";
import { secondsToTime } from "../../helper";

// CondensedTranscript Component
export const CondensedTranscript = ({
  condensedTranscript,
  currentSeek,
  setShowVideoPopup,
  setVideoInfos,
}) => {
  const transcriptRefs = useRef([]);
  const [highlightedIndex, setHighlightedIndex] = useState(null); // Track which paragraph is highlighted

  // Find the appropriate paragraph that matches the seek time period
  const getParagraphIndexForSeek = (seekTime) => {
    for (let i = 0; i < condensedTranscript.length; i++) {
      const currentStart = condensedTranscript[i].start;
      const nextStart =
        i + 1 < condensedTranscript.length
          ? condensedTranscript[i + 1].start
          : Infinity;

      // If the goal's start time falls within the current transcript's period
      if (seekTime >= currentStart && seekTime < nextStart) {
        return i;
      }
    }
    return -1; // Return -1 if no match is found
  };

  // Scroll to the corresponding transcript based on `currentSeek` and highlight it
  useEffect(() => {
    if (currentSeek !== null) {
      const targetIndex = getParagraphIndexForSeek(currentSeek);
      if (targetIndex !== -1 && transcriptRefs.current[targetIndex]) {
        transcriptRefs.current[targetIndex].scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        setHighlightedIndex(targetIndex); // Highlight the matched paragraph
        // Remove highlight after a short period
        setTimeout(() => setHighlightedIndex(null), 700); // Highlight period
      }
    }
  }, [currentSeek, condensedTranscript]);

  if (!condensedTranscript || condensedTranscript.length === 0) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <p className="text-gray-600">
          No transcript available for this section.
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-4 overflow-y-auto h-[100dvh] mt-[0dvh] ">
      <div className="h-[10dvh]"></div>
      {condensedTranscript.map((paragraph, index) => (
        <div
          key={index}
          ref={(el) => (transcriptRefs.current[index] = el)} // Assign each paragraph to a ref
          className={`p-4 border-b border-gray-200 transition-colors duration-500 hover:bg-gray-100 hover:cursor-pointer ease-in-out ${
            highlightedIndex === index ? "bg-gray-100" : "bg-white"
          }`} // Apply background color transition
          onClick={() => {
            setShowVideoPopup(true);
            setVideoInfos({
              time: paragraph.start,
              content: paragraph.content,
            });
          }} // Handle click to show video popup
        >
          <p className="text-sm text-gray-500">
            {secondsToTime(paragraph.start)}
          </p>
          <p className="text-base leading-relaxed">{paragraph.content}</p>
        </div>
      ))}
    </div>
  );
};
