import React from "react";
import Layout from "../Layout";
import { Resizable } from "re-resizable";

const Test = () => {
  return (
    <Layout>
      <div style={{ display: "flex", height: "100%", alignItems: "stretch" }}>
        <Resizable
          defaultSize={{
            width: "50%", // 初始宽度为50%
            height: "100%", // 高度匹配父容器
          }}
          minWidth="20%" // 最小宽度为20%
          maxWidth="80%" // 最大宽度为80%
          style={{ backgroundColor: "#ddd", overflow: "auto" }}
          handleStyles={{
            right: {
              width: "10px",
              backgroundColor: "#888",
              cursor: "ew-resize",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
          }}
          handleComponent={{
            right: (
              <div
                style={{
                  width: "10px",
                  backgroundColor: "#fff",
                  height: "100%",
                }}
              />
            ),
          }}
          enable={{ right: true }} // 允许通过右边界调整宽度
        >
          left
        </Resizable>
        <div style={{ flex: 1, backgroundColor: "#bbb", overflow: "auto" }}>
          right
        </div>
      </div>
    </Layout>
  );
};

export default Test;
