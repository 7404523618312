import { Modal } from "../../components/Modal";
import { secondsToTime } from "../helper";

export const VideoPopup = ({ setOpen, memorizedVideoPlayer, videoInfos }) => {
  const { content, time } = videoInfos;
  return (
    <Modal setOpen={setOpen}>
      <div className="flex flex-col items-center gap-4 bg-white z-40 rounded-lg m-4 overflow-hidden">
        <div
          id="youtube-video-player"
          className="relative w-full pt-[52.5%] z-10"
        >
          <button
            onClick={() => setOpen(false)}
            className="absolute top-0 right-2 text-white text-3xl rounded-full p-1 z-50 mb-2"
          >
            &times;
          </button>
          {setOpen && memorizedVideoPlayer}
        </div>
        <div className="px-4 py-2">
          <p className="text-sm text-gray-500 w-full">{secondsToTime(time)}</p>
          <div className="text-black z-50 pb-6">{content}</div>
        </div>
      </div>
    </Modal>
  );
};
