export const Avatar = ({ name, ...props }) => {
  return (
    <div className="items-center cursor-pointer hidden lg:flex" {...props}>
      <div className="flex items-center justify-center w-8 h-8 bg-gray-300 rounded-full">
        {name[0]}
      </div>
      {/* <div className="ml-2 font-semibold">{name}</div> */}
    </div>
  );
};
