import React from "react";
import styles from "./../Layout.module.css";
import { WatchPageHeader } from "./watchPageHeader";

const WatchPageLayout = ({
  children,
  lightsOn,
  setLightsOn,
  setIsChatbotOpen,
  videoTitle,
}) => {
  return (
    <div className={styles.Layout}>
      <div className={styles.mastheadContainer}>
        <WatchPageHeader
          lightsOn={lightsOn}
          setLightsOn={setLightsOn}
          setIsChatbotOpen={setIsChatbotOpen}
          videoTitle={videoTitle}
        />
      </div>
      <div
        className={
          lightsOn ? styles["watch-page-content"] : styles["content-lightsoff"]
        }
      >
        {children}
      </div>
    </div>
  );
};

export default WatchPageLayout;
