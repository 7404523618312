import React, { createContext, useState } from "react";

// Create the context
export const CreditModalContext = createContext();

// Create the provider component
export const CreditModalProvider = ({ children }) => {
  // State to manage credit modal open status
  const [creditModalOpen, setCreditModalOpen] = useState(false);

  return (
    <CreditModalContext.Provider
      value={{ creditModalOpen, setCreditModalOpen }}
    >
      {children}
    </CreditModalContext.Provider>
  );
};
