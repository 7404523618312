import React, { useState } from "react";
// import Layout from "../Layout";
import styles from "./login.module.css";
import Header from "../header/header";
import layoutStyles from "../Layout.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
// import { GoogleOAuthProvider, GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from "@react-oauth/google";
import { ParticlesComponent } from "../components/Particles";
import { useNavigate } from "react-router-dom";
import { googleLoginFetch, signinFetch } from "./helper";
import toastify from "../../toastify";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { CreditsContext } from "../header/CreditsContext";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const MemoizedParticlesComponent = React.memo(ParticlesComponent);

export const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { fetchCredits } = useContext(CreditsContext);
  const [referralCode, setReferralCode] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const location = useLocation();

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const ref = params.get("ref");
    if (ref) {
      setReferralCode(ref);
      // Optionally, store it in localStorage or context if needed elsewhere
      // localStorage.setItem("referralCode", ref);
    }
  }, [location.search]);

  const googleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      setIsProcessing(true);
      // // console.log("Google Login Success:", response);
      try {
        const res = await googleLoginFetch(response.access_token, referralCode);

        if (res.status === 200) {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("name", res.data.name);
          const redirectPage = localStorage.getItem("beforeSignInPage") || "/";
          localStorage.removeItem("beforeSignInPage");
          navigate(redirectPage);
          // console.log("google login done");
          toastify.custom(`Welcome ${res.data.name}!`);

          fetchCredits(res.data.token); // update number of credits on header
        } else {
          toastify.error(res.data.detail);
        }
      } catch (err) {
        toastify.error("Google sign in failed! Please try again.");
      } finally {
        setIsProcessing(false); // Reset processing state after completion
      }
    },
    onError: (errorResponse) => {
      toastify.error("Google sign in failed! Please try again.");
      setIsProcessing(false);
    },
  });

  const handleSignIn = async (e) => {
    setIsProcessing(true);

    const isSignIn = localStorage.getItem("token") !== "DEMO_TOKEN";

    if (isSignIn) {
      toastify.error("You already sign in");
      setIsProcessing(false);
    } else {
      e.preventDefault();
      try {
        const res = await signinFetch(email, password);
        // console.log(res.data);
        if (res.status === 200) {
          // console.log("Sign in successful:", res);
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("name", res.data.name);
          toastify.custom(`Welcome ${res.data.name}!`);
          const redirectPage = localStorage.getItem("beforeSignInPage") || "/";
          localStorage.removeItem("beforeSignInPage");
          navigate(redirectPage);

          fetchCredits(res.data.token); // update number of credits on header
        } else {
          console.error("Sign in failed:", res);
          toastify.error(res.data.detail);
        }
      } catch (err) {
        toastify.error(
          "An error occurred while signing you in! Please try again.",
        );
        console.error("Error during sign in: ", err);
      } finally {
        setIsProcessing(false); // Reset processing state after completion
      }
    }
  };

  const handleGoogleSigninClick = () => {
    const isSignIn = localStorage.getItem("token") !== "DEMO_TOKEN";
    if (isSignIn) {
      toastify.error("You already sign in.");
    } else {
      setIsProcessing(true);
      googleLogin();
    }
  };

  return (
    <div className={layoutStyles.Layout}>
      <Header />
      <MemoizedParticlesComponent />
      <div className={styles["login-container"]}>
        <div className="flex justify-between items-center w-full">
          <h2 className={styles["signup-title"]}>Sign in</h2>
          <span
            className={styles["signin-prompt"]}
            onClick={() => navigate("/signup")}
          >
            {" "}
            I don't have an account
          </span>
        </div>

        <form onSubmit={handleSignIn}>
          <input
            type="text"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <div className={styles["password-container"]}>
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button
              type="button"
              className={styles["toggle-password"]}
              onClick={togglePasswordVisibility}
            >
              <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
            </button>
          </div>
          <button
            className={styles["login-button"]}
            type="submit"
            disabled={isProcessing}
          >
            {isProcessing ? "Signing you in..." : "Sign in"}
          </button>
        </form>
        <div className={styles.terms}>
          By signing in, you agree to the{" "}
          <Link to="/terms">Terms and Conditions</Link> and{" "}
          <Link to="/privacy">Privacy Policy</Link>.
        </div>
        <div
          className={styles["social-login"]}
          onClick={() => handleGoogleSigninClick("google")}
        >
          <div className={styles["social-button"]}>
            <img
              class="w-5 h-5"
              src="https://www.svgrepo.com/show/475656/google-color.svg"
              loading="lazy"
              alt="google logo"
            />
            <span className="ml-1">Sign in with Google</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
