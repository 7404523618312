import React from "react";
import styles from "../studyspace-page/StudyspaceChatbotModal.module.css";

export const ModalWrapper = ({ children, setOpen }) => {
  return (
    <div className={styles.modalWrapper}>
      <div className={styles.modalContent}>
        <button className={styles.exitButton} onClick={() => setOpen(false)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={styles.closeIcon}
          >
            <line x1="18" y1="6" x2="6" y2="18" />
            <line x1="6" y1="6" x2="18" y2="18" />
          </svg>
        </button>
        <div className={styles.chatArea}>
          <div className={styles.chatComponentContainer}>{children}</div>
        </div>
      </div>
    </div>
  );
};
