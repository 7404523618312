import React from "react";
import { useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import ediLogo from "../../edi-logo-transparent.png";
import styles from "./header.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import toastify from "../../toastify";
import { VideoModalContext } from "./../VideoModalContext";
import { logoutFetch } from "../login-page/helper";
import { useNavigate } from "react-router-dom";
import { ContactModal } from "./contact-modal";
import { Menu } from "../components/menu";
import { MagicButton } from "./magic-button";
import PromptEarnCreditModal from "./promptEarnCreditModal";
import { CreditsContext } from "./CreditsContext";
import { CreditModalContext } from "./CreditModalContext";
import { faArrowRightToBracket } from "@fortawesome/free-solid-svg-icons";
import { DiscordButton } from "../components/discord-button";
import { useEffect } from "react";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
const Header = () => {
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("token") !== "DEMO_TOKEN" &&
      localStorage.getItem("token") !== null,
  );
  const { credits } = useContext(CreditsContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { generateVideoOpen, toggleGenerateVideo } =
    useContext(VideoModalContext);
  const navigate = useNavigate();

  const { creditModalOpen, setCreditModalOpen } =
    useContext(CreditModalContext);

  const logOutExecutor = () => {
    localStorage.setItem("token", "DEMO_TOKEN");
    localStorage.setItem("name", "Not Logged In");
    navigate("/");
    //toastify.success("Logout successful");
    window.location.reload();
    setIsLoggedIn(false);
  };

  const logOut = async () => {
    if (!localStorage.getItem("token")) {
      logOutExecutor();
    }
    try {
      const res = await logoutFetch(localStorage.getItem("token"));
      // console.log(res);
      if (res.status === 200) {
        logOutExecutor();
        // window.location.href = "/signin";
      } else {
        const errorData = await res.json();
        toastify.error("Logout failed! Please try again.");
      }
    } catch (err) {
      toastify.error("Logout failed! Please try again.");
    }
  };

  const signIn = () => {
    localStorage.setItem("beforeSignInPage", window.location.pathname);
    // console.log(window.location.pathname);
    navigate("/signin");
  };

  const isSignupPage =
    location.pathname === "/signup" || location.pathname === "/signin";

  const [isMobile, setIsMobile] = useState(window.innerWidth < 980);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 980);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // console.log(isModalOpen);
  return (
    <div className={styles.header}>
      {creditModalOpen && (
        <PromptEarnCreditModal setCreditModalOpen={setCreditModalOpen} />
      )}

      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 w-full">
        <nav className="flex items-center justify-between h-full w-full">
          <div className="flex gap-0">
            <Link to="/" title="Home" className="flex items-center h-full">
              <img
                className="w-auto h-8 lg:h-[2.15rem]"
                src={ediLogo}
                alt="Edi"
              />
            </Link>
            {!isMobile && (
              <div className="ml-5 inline-flex">
                <DiscordButton />
              </div>
            )}
          </div>

          {/* Shows up when in mobile view */}
          {isMobile && (
            <div className="flex gap-3 items-center">
              {credits === null ? null : credits > 0 ? (
                <div
                  className={styles.credits}
                  onClick={() => {
                    setCreditModalOpen(true);
                  }}
                >
                  {credits} credits left
                </div>
              ) : (
                <div
                  className={styles.credits}
                  onClick={() => {
                    setCreditModalOpen(true);
                  }}
                >
                  Want more credits?
                </div>
              )}
              {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                  <ContactModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                  />
                </div>
              )}
              <a
                href="https://discord.gg/4nSVGv8A3g"
                title="Join our Discord"
                rel="noopener noreferrer"
                className="inline-flex justify-center items-center bg-transparent border-2 border-[#150940] rounded-lg cursor-pointer w-[42px] h-[34px] transition-all duration-200 hover:bg-gray-100 focus:bg-gray-100"
                role="button"
                target="_blank"
                id="discord-header-button"
              >
                <FontAwesomeIcon icon={faDiscord} />
              </a>

              <MagicButton toggleGenerateVideo={toggleGenerateVideo} />
              <Menu
                logOut={logOut}
                isLoggedIn={isLoggedIn}
                signIn={signIn}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
              />
            </div>
          )}

          {/* Shows up when the screen is large */}
          <div className="hidden md:flex md:items-center md:ml-auto md:space-x-10">
          <MagicButton toggleGenerateVideo={toggleGenerateVideo} />
            <Link
              to="/"
              title=""
              className={
                location.pathname === "/"
                  ? `${styles.link} ${styles.active}`
                  : styles.link
              }
            >
              Studyspaces
            </Link>

            <Link
              to="/about"
              title=""
              className={
                location.pathname === "/about"
                  ? `${styles.link} ${styles.active}`
                  : styles.link
              }
            >
              About Us
            </Link>

            <div className={styles.contactWrapper}>
              <button
                onClick={() => setIsModalOpen(!isModalOpen)}
                title="Contact Us"
                className={`${styles.link} ${isModalOpen ? styles.active : ""}`}
              >
                Contact Us
              </button>
              <ContactModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
              />
            </div>

            {credits === null ? null : credits > 0 ? (
              <div
                className={styles.credits}
                onClick={() => {
                  setCreditModalOpen(true);
                }}
              >
                {credits} credits left
              </div>
            ) : (
              <div
                className={styles.credits}
                onClick={() => {
                  setCreditModalOpen(true);
                }}
              >
                Want more credits?
              </div>
            )}

            {isLoggedIn && (
              <Menu logOut={logOut} isLoggedIn={isLoggedIn} signIn={signIn} />
            )}

            {!isLoggedIn && (
              <button
                title="Sign In to Access All Features"
                className={styles["unlock-feature"]}
                onClick={signIn}
              >
                <FontAwesomeIcon
                  icon={faArrowRightToBracket}
                  className="mr-2"
                />
                <strong>
                  <p>Sign In to Access All Features</p>
                </strong>
              </button>
            )}
          </div>
        </nav>
      </div>
    </div>
  );
};

export default React.memo(Header);
