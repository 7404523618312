export const Modal = ({ setOpen, children }) => {
  return (
    <div
      className={`fixed top-0 left-0 h-full w-full z-20 flex justify-center items-center`}
    >
      <div
        className="absolute top-0 left-0 h-full w-full bg-gray-600 opacity-50"
        onClick={() => setOpen(false)}
      ></div>
      {children}
    </div>
  );
};
