import React, { useLayoutEffect, useRef, useState } from "react";
import { SummaryCard } from "./SummaryCard";
import { GoalCard } from "./GoalCard";
import { CondensedTranscript } from "./CondensedTranscript";

export const Content = ({
  currentSegment,
  selected,
  setSelected,
  contentItems,
  currentContentIndex,
  setVideoInfos,
  setShowVideoPopup,
  total_steps
}) => {
  const contentRefs = useRef([]);
  const [currentSeek, setCurrentSeek] = useState(null);

  // Use useLayoutEffect to scroll the current content into view when currentContentIndex changes
  useLayoutEffect(() => {
    const ref = contentRefs.current[currentContentIndex];
    if (ref) {
      ref.scrollIntoView(
        {
          behavior: "smooth",
          block: "center",
        },
        50
      );
    }
  }, [currentContentIndex, selected]);

  const scrollMarginTopClass = "scroll-mt-10";

  if (selected === "Transcripts") {
    return (
      <div className="flex">
        <CondensedTranscript
          condensedTranscript={currentSegment.condensed_transcript}
          setVideoInfos={setVideoInfos}
          setShowVideoPopup={setShowVideoPopup}
          currentSeek={currentSeek}
        />
        <div className="w-full flex justify-center absolute">
          <div
            className="fixed bg-white px-4 py-2 rounded-lg bottom-[10dvh] md:hidden border-2 border-blue-500 text-blue-500 shadow-md cursor-pointer"
            onClick={() => setSelected("Content")}
          >
            Back to Cards
          </div>
        </div>
      </div>
    );
  }

  const handleSeek = (start) => {
    setCurrentSeek(start); // Update the current seek to scroll
    setSelected("Transcripts");
  };

  // Render all content items
  return (
    <div>
      {contentItems.map((contentItem, index) => {
        const { type, data } = contentItem;

        if (type === "Starting") {
          return (
            <div
              key={index}
              ref={(el) => (contentRefs.current[index] = el)}
              className={`${scrollMarginTopClass} mt-[10dvh] h-[75dvh] md:h-[30dvh] mb-12`}
            >
              <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-xl shadow-lg flex flex-col items-center justify-center text-center border border-blue-300 relative h-[75dvh]">
                <p className="text-blue-700 font-semibold text-2xl tracking-wide mb-4">
                  Welcome to the first section of the video!
                </p>
                <p className="text-gray-600 mt-2 text-base">
                  Scroll up to begin your journey.
                </p>
                {/* Decorative element */}
                <div className="absolute top-4 right-4 bg-blue-200 w-6 h-6 rounded-full animate-pulse"></div>
                <div className="absolute bottom-4 left-4 bg-blue-200 w-6 h-6 rounded-full animate-pulse"></div>
              </div>
            </div>
          );
        }

        if (type === "Summary") {
          if (
            !data ||
            data.toLowerCase() === "no summary available for this section."
          ) {
            return (
              <div
                className={`flex mt-[10dvh] items-center justify-center w-full h-[75dvh] ${scrollMarginTopClass}`}
                key={index}
                ref={(el) => (contentRefs.current[index] = el)}
              >
                <div className=""><p className="text-gray-600">
                  No Summary Card available for this section
                </p>
                {/* Scroll down hint */}
                <p className="text-gray-500 text-sm md:hidden">
                  Scroll up to the next section
                </p></div>
                
                <div className="fixed bottom-[16dvh] font-medium">Or</div>
                <div
                  className="fixed bg-white px-4 py-2 rounded-lg bottom-[10dvh] md:hidden border-2 border-blue-500 text-blue-500 shadow-md cursor-pointer"
                  onClick={() => setSelected("Transcripts")}
                >
                  Go to Transcript
                </div>
              </div>
            );
          }
          return (
            <div
              key={index}
              ref={(el) => (contentRefs.current[index] = el)}
              className={`${scrollMarginTopClass} mt-[10dvh] h-[75dvh] md:h-[30dvh] mb-12`}
            >
              <div className="bg-gradient-to-b from-white to-gray-50 p-6 rounded-lg shadow-lg flex flex-col items-start border-l-4 border-blue-500 relativ h-[75dvh]">
                {/* Icon for a techy feel */}
                <div className="text-blue-500 mb-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 8c1.657 0 3-1.343 3-3S13.657 2 12 2 9 3.343 9 5s1.343 3 3 3zM12 14c-4.418 0-8 1.79-8 4v2h16v-2c0-2.21-3.582-4-8-4z"
                    />
                  </svg>
                </div>
                <p className="text-blue-700 font-semibold text-lg tracking-wide mb-2">
                  Section Summary
                </p>
                <p className="text-gray-800 text-base font-medium mt-4 leading-relaxed">
                  {data}
                </p>
                {/* Subtle shadow element to add depth */}
                <div className="absolute inset-0 rounded-lg bg-blue-100 opacity-10 blur-sm"></div>
              </div>
            </div>

          );
        }

        if (type === "Goal") {
          const goal = data;
          return (
            <div
              key={index}
              ref={(el) => (contentRefs.current[index] = el)}
              className={`${scrollMarginTopClass} mt-[10dvh] h-[75dvh] md:h-[30dvh] mb-12`}
              onClick={() => handleSeek(data.start)}
            >
              <div className="transition-all duration-300 ease-in-out transform bg-gradient-to-b from-white to-gray-50 p-6 rounded-xl shadow-md flex flex-col items-start border-l-4 border-indigo-500 h-[75dvh] relative active:scale-95 active:shadow-md">
                {/* Icon for objectives */}
                <div className="text-indigo-500 mb-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
                <p className="text-indigo-700 font-semibold text-lg tracking-wide mb-2">
                  Learning Objective
                </p>
                <p className="text-gray-700 text-sm mb-2">
                  Step {goal.step_number} of {total_steps - 1}
                </p>
                {/* Highlighted Explanation Section */}
                <p className="text-gray-900 text-lg font-semibold leading-relaxed">
                  {goal.learning_goal}
                </p>
                <p className="text-gray-800 text-base font-medium mt-4 leading-relaxed">
                  {goal.explanation}
                </p>
                {/* Subtle background element */}
                <div className="absolute inset-0 rounded-xl bg-indigo-100 opacity-10 blur-md"></div>
              </div>
            </div>


          );
        }

        return null;
      })}
    </div>
  );
};
