import React, { useEffect, useState } from "react";
import { dotSpinner } from "ldrs";
import { MobileWatch } from "./mobile-watch";
import { DesktopWatch } from "./desktop-watch";

dotSpinner.register();

const Watch = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 980);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 980);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (isMobile) {
    return <MobileWatch />;
  }

  return <DesktopWatch />;
};

export default Watch;
