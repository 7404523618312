import { useSetState } from "react-use";
import Joyride, { STATUS } from "react-joyride";
import { useEffect } from "react";
import Lottie from "react-lottie";
import * as animationData from "../components/logos/animation-welcome.json";
import { DiscordButton } from "../components/discord-button";

export const StudyspaceTour = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // State for Joyride -- Tutorial
  const [{ run, steps }, setState] = useSetState({
    run: false,
    steps: [
      {
        content: (
          <h2>
            This Chatbot is trained on <strong>all the videos</strong> in
            current Study Space!
          </h2>
        ),
        target: "#studySpaceChatBot",
        title: <div className="mt-3 font-bold">Study Space Chatbot</div>,
        locale: { last: "Got it!" },
      },
    ],
  });

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setState({ run: false });
      localStorage.setItem("studyspaceTourCompleted", "true");
    }
  };

  // Check if this is the first visit to the page
  // If it is, show the tutorial
  useEffect(() => {
    const studyspaceTourCompleted = localStorage.getItem(
      "studyspaceTourCompleted",
    );
    if (!studyspaceTourCompleted) {
      setState({ run: true });
    }
  }, []);

  return (
    <Joyride
      callback={handleJoyrideCallback}
      continuous
      run={run}
      showSkipButton
      steps={steps}
      disableScrolling={true}
      styles={{
        options: {
          primaryColor: "#1976d2",
          zIndex: 1000,
        },
        tooltipFooter: {
          marginTop: "0px", // Reduce the top margin for the entire footer section
        },
      }}
    />
  );
};
