import React, { createContext, useState } from "react";

// Create the context
export const VideoModalContext = createContext();

// Create the provider component
export const VideoModalProvider = ({ children }) => {
  const [generateVideoOpen, setGenerateVideoOpen] = useState(false); // Initially closed

  const toggleGenerateVideo = () => {
    setGenerateVideoOpen((prevState) => !prevState); // Toggle the modal open/close state
  };

  return (
    <VideoModalContext.Provider
      value={{ generateVideoOpen, setGenerateVideoOpen, toggleGenerateVideo }}
    >
      {children}
    </VideoModalContext.Provider>
  );
};
