import React, { useEffect, useState, useContext } from "react";
import Layout from "../Layout";
import { useNavigate, useParams } from "react-router-dom";
import { getVideoListFromStudyspace } from "../watch-page/helper";
import styles from "./Home.module.css";
import Popup from "../studyspace-page/Popup";
import { authFetch } from "../watch-page/helper";
import toastify from "../../toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { GenerateVideoModal } from "../generateVideoModal";
import { VideoModalContext } from "../VideoModalContext";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import { dotSpinner } from "ldrs";
import { HomeTour } from "./HomeTour";

dotSpinner.register();

// console.log(process.env);
const getStudyspaceList = async (token) => {
  const path = "studyspace/list";
  // console.log(token);
  return await authFetch("GET", path, token);
};

const YOUTUBE_API_KEY = "AIzaSyB7-9M_aHuIvq865mSW_axBtnkdY_P3pUU";

const fetchYouTubeDetails = async (video) => {
  const videoId = extractYouTubeID(video.link);
  const url = `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${videoId}&key=${YOUTUBE_API_KEY}`;
  const response = await fetch(url);
  const data = await response.json();
  if (data.items.length > 0) {
    const snippet = data.items[0].snippet;
    return {
      ...video,
      thumbnailUrl: snippet.thumbnails.high.url,
      title: snippet.title,
      author: snippet.channelTitle,
      publishDate: snippet.publishedAt, // Fetch the publish date
    };
  }
  throw new Error("Video not found");
};

const extractYouTubeID = (url) => {
  try {
    // Check if the URL is valid
    const parsedUrl = new URL(url);
    // Extract the 'v' parameter from the URL
    const urlParams = new URLSearchParams(parsedUrl.search);
    const videoID = urlParams.get("v");

    // If the 'v' parameter exists and is not empty, return it
    if (videoID) {
      return videoID;
    }

    // Check for alternate YouTube URL formats (e.g., https://youtu.be/VIDEO_ID)
    const pathSegments = parsedUrl.pathname.split("/");
    if (pathSegments.length > 1 && pathSegments[1].length === 11) {
      return pathSegments[1];
    }

    // If no valid ID is found, return null
    return null;
  } catch (error) {
    // In case of an invalid URL or other errors, return null gracefully
    return null;
  }
};

export const Home = () => {
  const navigate = useNavigate();
  const [youtubeURL, setYoutubeURL] = useState("");
  const [studyspaces, setStudyspaces] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [popupConfig, setPopupConfig] = useState({
    message: "",
    primaryLabel: "",
    secondaryLabel: "",
    onPrimaryAction: null,
    onSecondaryAction: null,
  });
  const apiUrl = process.env.REACT_APP_API_URL || process.env.API_URL;

  const videoServerUrl =
    process.env.REACT_APP_VIDEO_PROCESS_SERVER_URL ||
    process.env.VIDEO_PROCESS_SERVER_URL;

  const { generateVideoOpen, toggleGenerateVideo } =
    useContext(VideoModalContext);

  const token = localStorage.getItem("token");

  const fetchStudyspaces = async () => {
    try {
      const res = await getStudyspaceList(token);

      if (res.data.studyspaces) {
        setStudyspaces(res.data.studyspaces);
      }
    } catch (err) {
      toastify.error(
        "Failed to fetch studyspaces! Please contact admin on discord.",
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchStudyspaces();
  }, []);

  const handleURLChange = (event) => {
    setYoutubeURL(event.target.value);
  };

  const handleSubmit = async () => {
    setShowPopup(false);
    const videoId = extractYouTubeID(youtubeURL);
    if (!videoId) {
      toastify.error("Please enter valid YouTube URL!");
      return;
    }
    try {
      const response = await fetch(`${videoServerUrl}/submit_video`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ video_id: videoId }),
      });

      const data = await response.json();
      if (response.status === 202 || response.status === 200) {
        toastify.custom(
          `🦄       Video successfully submitted! It will appear in your personal studyspace.`,
        );
        setYoutubeURL("");
      } else {
        toastify.error(`Failed to process video! ${data.message}`);
      }
    } catch (err) {
      toastify.error(`Failed to process video! ${err}`);
    }
  };

  const openSubmitPopup = () => {
    setPopupConfig({
      message:
        "You're about to use 1 free credit. Are you sure you want to continue?",
      primaryLabel: "Yes, Submit",
      secondaryLabel: "No, Cancel",
      onPrimaryAction: () => {
        handleSubmit();
      },
      onSecondaryAction: () => {
        setShowPopup(false);
      },
    });
    setShowPopup(true);
  };

  const isSignedIn = localStorage.getItem("token") !== "DEMO_TOKEN";

  return (
    <>
      {!isLoading && <HomeTour />}

      {showPopup && (
        <Popup
          message={popupConfig.message}
          primaryLabel={popupConfig.primaryLabel}
          secondaryLabel={popupConfig.secondaryLabel}
          onPrimaryAction={popupConfig.onPrimaryAction}
          onSecondaryAction={popupConfig.onSecondaryAction}
        />
      )}
      <Layout>
        {generateVideoOpen && (
          <GenerateVideoModal
            setShowPopup={setShowPopup}
            setPopupConfig={setPopupConfig}
            fetchVideoList={() => {}}
          />
        )}

        <div className={styles.studyspaceSection}>
          <h2 className={styles.sectionTitle}>
            Your Study Spaces
            <span className={styles.infoIcon}>
              <FontAwesomeIcon icon={faCircleInfo} />
              <span className={styles.tooltipText}>
                Study spaces are collections of videos.
              </span>
            </span>
          </h2>
          {isLoading && (
            <div className={styles.loader}>
              <l-dot-spinner
                size="40"
                speed="0.9"
                color="black"
              ></l-dot-spinner>
            </div>
          )}
          <div
            className={`${isLoading ? "opacity-0" : "opacity-100"} transition-opacity duration-200 ease-in-out`}
          >
            <div className={styles.gridContainer} id="studySpace">
              {!isSignedIn && studyspaces.length === 0 ? (
                <Link to="/signin" className={styles.noStudyspacesMessage}>
                  <p>
                    Please{" "}
                    <strong className={styles.signInLink}>Sign in</strong> to
                    have your personal studyspace and access all public
                    studyspaces.
                  </p>
                </Link>
              ) : studyspaces.length === 0 && !isLoading ? (
                <a
                  href="https://discord.gg/4nSVGv8A3g"
                  title="Join our discord"
                  rel="noopener noreferrer"
                  role="button"
                  target="_blank"
                  className={styles.noStudyspacesMessage}
                >
                  <p>
                    <strong>Don’t have any studyspaces yet?</strong> <br />
                    Don’t panic! We’ve got you covered. Join our Discord and let
                    us know! Click me!
                  </p>
                </a>
              ) : (
                studyspaces.map((studyspace) => (
                  <div
                    key={studyspace._id}
                    className={styles.studyspaceCard}
                    onClick={() => navigate(`/studyspace/${studyspace._id}`)}
                  >
                    <div className={styles.iconWrapper}></div>
                    <div className={styles.cardContent}>
                      <h3>{studyspace.name}</h3>
                      <p>{studyspace.videos.length} Video(s)</p>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
