import Layout from "../Layout";

export const Privacy = () => {
  return (
    <Layout>
      <div className="max-w-5xl mx-auto px-6 py-12">
        <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>

        <h2 className="text-2xl font-semibold mt-8 mb-4">1. Chat History</h2>
        <p className="mb-4">
          Edi does not store your chat history in our database. Any
          conversations with our chatbot are processed in real-time and are
          retained locally. This ensures your interactions remain private.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          2. Information We Collect
        </h2>
        <p className="mb-4">
          We may collect personal information when you use our services,
          including:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>
            Your name, email address when you create an account or contact us.
          </li>
          <li>
            Usage data such as your interactions with the platform and
            preferences.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          3. How We Use Your Information
        </h2>
        <p className="mb-4">The information we collect is used to:</p>
        <ul className="list-disc list-inside mb-4">
          <li>Provide, maintain, and improve our services.</li>
          <li>Respond to your inquiries and support requests.</li>
          <li>Analyze usage trends and enhance user experience.</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4">4. Data Security</h2>
        <p className="mb-4">
          We take appropriate measures to safeguard your personal information
          from unauthorized access, disclosure, or alteration. However, please
          be aware that no data transmission over the internet is completely
          secure.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          5. Sharing of Information
        </h2>
        <p className="mb-4">
          We do not sell or rent your personal information to third parties. We
          may share your data with trusted third-party service providers who
          assist us in delivering our services, provided they adhere to strict
          confidentiality agreements.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">6. Your Rights</h2>
        <p className="mb-4">
          You have the right to access, correct, or delete your personal data at
          any time. If you wish to update or remove your information, please use
          the "Contact Us" section at the top.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          7. Changes to This Privacy Policy
        </h2>
        <p className="mb-4">
          We may update this Privacy Policy periodically to reflect changes in
          our practices or legal requirements.
        </p>

        <p className="mt-8">
          If you have any questions or concerns about this Privacy Policy,
          please use the "Contact Us" section at the top, and we will get back
          to you shortly.
        </p>
      </div>
    </Layout>
  );
};
