import { authFetch } from "../watch-page/helper";

export const googleLoginFetch = async (accessToken, referralCode) => {
  const path = `api/v1/auth/google`;
  const body = { token: accessToken, referral_code: referralCode };
  return await authFetch("POST", path, null, body);
};

export const signupFetch = async (email, name, password, referralCode) => {
  const path = `signup`;
  const body = {
    email,
    name,
    password,
    referralCode,
  };
  return await authFetch("POST", path, null, body);
};

export const signinFetch = async (email, password) => {
  const path = `signin`;
  const body = {
    email,
    password,
  };
  return await authFetch("POST", path, null, body);
};

export const logoutFetch = async (token) => {
  const path = `logout`;
  return await authFetch("POST", path, token);
};

export const creditsFetch = async (token) => {
  const path = `credits`;
  return await authFetch("GET", path, token);
};

export const refLinkFetch = async (token) => {
  const path = `reflink`;
  return await authFetch("GET", path, token);
};

export const feedbackFetch = async (token, feedback) => {
  const path = `feedback`;
  const body = {
    feedback,
  };
  return await authFetch("POST", path, token, body);
};
