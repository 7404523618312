import { authFetch } from "../watch-page/helper";

export const getAnswerInStudyspace = async (
  history,
  query,
  studyspaceId,
  config,
  token,
) => {
  const path = `generate-answer-studyspace/${studyspaceId}`;
  const body = {
    history: history,
    query: query,
    config: config,
  };
  return await authFetch("POST", path, token, body);
};

export const secondsToTime = (totalSeconds) => {
  // Convert to number if input is a string
  if (typeof totalSeconds === "string") {
    totalSeconds = parseInt(totalSeconds, 10);
    if (isNaN(totalSeconds)) {
      return "Invalid input"; // Return error message if conversion fails
    }
  }

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  // Padding the hours, minutes, and seconds with leading zeros if they are less than 10
  const paddedHours = hours.toString().padStart(2, "0");
  const paddedMinutes = minutes.toString().padStart(2, "0");
  const paddedSeconds = seconds.toString().padStart(2, "0");

  return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
};
