import React, { useState } from "react";
import { secondsToTime } from "../helper";
import { Tabs } from "../../components/Tabs";
import { TimelineNavigator } from "./timeline-navigator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { useRef } from "react";

// CondensedTranscript Component
const CondensedTranscript = ({ condensedTranscript, currentSeek }) => {
  const transcriptRefs = useRef([]);
  const [highlightedIndex, setHighlightedIndex] = useState(null); // Track which paragraph is highlighted

  // Find the appropriate paragraph that matches the seek time period
  const getParagraphIndexForSeek = (seekTime) => {
    for (let i = 0; i < condensedTranscript.length; i++) {
      const currentStart = condensedTranscript[i].start;
      const nextStart =
        i + 1 < condensedTranscript.length
          ? condensedTranscript[i + 1].start
          : Infinity;

      // If the goal's start time falls within the current transcript's period
      if (seekTime >= currentStart && seekTime < nextStart) {
        return i;
      }
    }
    return -1; // Return -1 if no match is found
  };

  // Scroll to the corresponding transcript based on `currentSeek` and highlight it
  React.useEffect(() => {
    if (currentSeek !== null) {
      const targetIndex = getParagraphIndexForSeek(currentSeek);
      if (targetIndex !== -1 && transcriptRefs.current[targetIndex]) {
        transcriptRefs.current[targetIndex].scrollIntoView({
          behavior: "smooth",
        });
        setHighlightedIndex(targetIndex); // Highlight the matched paragraph
        // Remove highlight after a short period
        setTimeout(() => setHighlightedIndex(null), 700); // Highlight period
      }
    }
  }, [currentSeek, condensedTranscript]);

  if (!condensedTranscript || condensedTranscript.length === 0) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <p className="text-gray-600">
          No transcript available for this section.
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {condensedTranscript.map((paragraph, index) => (
        <div
          key={index}
          ref={(el) => (transcriptRefs.current[index] = el)} // Assign each paragraph to a ref
          className={`p-4 border-b border-gray-200 transition-colors duration-500 ease-in-out ${
            highlightedIndex === index ? "bg-gray-100" : "bg-white"
          }`} // Apply background color transition
        >
          <p className="text-sm text-gray-500">
            {secondsToTime(paragraph.start)}
          </p>
          <p className="text-base leading-relaxed">{paragraph.content}</p>
        </div>
      ))}
    </div>
  );
};
// GoalCard Component
const GoalCard = ({
  step_number,
  learning_goal,
  explanation,
  start,
  handleSeek,
}) => {
  return (
    <div
      className="bg-white border border-gray-200 rounded-lg p-6 mb-4 shadow-md cursor-pointer"
      onClick={() => handleSeek(start)} // Handle click to scroll to corresponding transcript
    >
      <div className="mb-4">
        <h2 className="text-lg font-bold text-gray-700">{step_number}</h2>
      </div>
      <div>
        <h3 className="text-blue-500 font-semibold text-base mb-2">
          {learning_goal}
        </h3>
        <p className="text-gray-600 text-sm leading-relaxed">{explanation}</p>
      </div>
    </div>
  );
};

const Content = ({ currentSegment, selected, setSelected, onSeek, _id }) => {
  const [currentSeek, setCurrentSeek] = useState(null);

  const handleSeek = (start) => {
    setCurrentSeek(start); // Update the current seek to scroll
    setSelected("Transcripts");
  };

  if (selected === "Summary") {
    return <p className="text-base">{currentSegment.summary}</p>;
  }
  if (selected === "Steps") {
    if (!currentSegment.goals || currentSegment.goals.length === 0) {
      return (
        <div className="flex items-center justify-center w-full h-full">
          <p className="text-gray-600">No steps available for this section.</p>
        </div>
      );
    }
    return (
      <div>
        {currentSegment.goals.map((goal, index) => (
          <GoalCard
            key={index}
            step_number={goal.step_number}
            learning_goal={goal.learning_goal}
            explanation={goal.explanation}
            start={goal.start} // Pass the start time to the GoalCard
            handleSeek={handleSeek} // Pass the seek handler
          />
        ))}
      </div>
    );
  }

  if (selected === "Transcripts") {
    return (
      <CondensedTranscript
        condensedTranscript={currentSegment.condensed_transcript}
        currentSeek={currentSeek} // Pass the seek state
      />
    );
  }
  return <></>;
};

const DesktopVideoInfo = ({
  currentSegment,
  currentSegmentIntro,
  onSeek,
  _id,
  segmentsLength,
  setCurrentSegmentIndex,
  currentSegmentIndex,
  setIsTimelineModalOpen,
  setIsChatbotOpen,
  currentSegmentStart,
  currentSegmentEnd,
  startOnboarding,
  isExpanded,
  setIsExpanded,
}) => {
  // Used for tabs between Summary, Steps and Transcripts
  const tabs = ["Summary", "Steps", "Transcripts"];
  const [selected, setSelected] = useState(tabs[0]);
  // State to manage whether the modal is expanded or collapsed

  // Function to toggle between expanded and collapsed states
  const toggleModal = () => {
    setIsExpanded(!isExpanded);
  };

  if (!currentSegment) {
    return <EmptyInfo />;
  }
  console.log(currentSegment);
  return (
    <div
      id="video-info-container"
      style={{
        width: "100%",
        flex: 1,
        display: "flex",
        border: "1px solid #d5d9e8",
        flexDirection: "column",
        padding: "20px",
        paddingTop: "0px",
        borderRadius: "8px",
        overflowY: "auto",
        backgroundColor: "white",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div id="video-info" className="w-full h-full flex flex-col items-center">
        <div
          className="fixed bg-white px-4 py-1 rounded-full mt-4"
          style={{
            border: "1px solid #d5d9e8",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Tabs
            tabs={tabs}
            selected={selected}
            setSelected={setSelected}
            className={"hidden md:flex"}
          />
        </div>

        {/* Mobile Start Time and Title */}
        <div className="mt-4 md:hidden" id="sectionTitle">
          <span className="text-gray-500 font-medium text-sm block">
            {currentSegmentStart} {" - "} {currentSegmentEnd}
          </span>
          <h4 className="text-gray-700 font-semibold text-lg mt-1">
            {currentSegmentIntro}
          </h4>
        </div>

        {/* Content */}
        <div className="mt-4 overflow-y-auto">
          <div id="sectionContent">
            <div className="h-[9dvh]"></div>
            <Content
              _id={_id}
              currentSegment={currentSegment}
              selected={selected}
              onSeek={onSeek}
              setSelected={setSelected}
            />
          </div>

          <div className="h-[35dvh] md:h-[5dvh]"></div>
        </div>

        <div
          className={`absolute flex justify-center items-center left-1/2 bottom-6 transform -translate-x-1/2 ${
            isExpanded ? "h-[12dvh]" : "h-[30dvh]"
          } w-[90vw] md:hidden rounded-lg bg-white`}
          style={{
            border: "1px solid #d5d9e8",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          {/* Minus Icon to collapse */}
          <button
            className="absolute top-2 right-4 text-gray-500 cursor-pointer"
            onClick={toggleModal}
          >
            {isExpanded ? (
              <FontAwesomeIcon icon={faPlus} />
            ) : (
              <FontAwesomeIcon icon={faMinus} />
            )}
          </button>

          {/* user onboard trigger */}
          <button
            className="absolute top-2 left-4 text-gray-500 cursor-pointer"
            onClick={startOnboarding}
          >
            <FontAwesomeIcon icon={faQuestionCircle} /> {/* Replaced icon */}
          </button>
          {
            <TimelineNavigator
              segmentsLength={segmentsLength}
              setIsChatbotOpen={setIsChatbotOpen}
              setCurrentSegmentIndex={setCurrentSegmentIndex}
              currentSegmentIndex={currentSegmentIndex}
              setIsTimelineModalOpen={setIsTimelineModalOpen}
              tabs={tabs}
              selected={selected}
              setSelected={setSelected}
              isExpanded={isExpanded}
            />
          }
        </div>
      </div>
    </div>
  );
};

// EmptyInfo component will be rendered when there is no currentSegment
const EmptyInfo = () => {
  return (
    <div
      id="video-info-container"
      style={{
        width: "calc(100% - 10px)",
        flex: 1,
        display: "flex",
        border: "1px solid #d5d9e8",
        flexDirection: "column",
        padding: "20px",
        borderRadius: "8px",
        overflowY: "auto",
        backgroundColor: "white",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div id="video-info" className="w-full h-full"></div>
    </div>
  );
};

export default DesktopVideoInfo;
