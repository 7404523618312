import { useSetState } from "react-use";
import Joyride, { STATUS } from "react-joyride";
import { useEffect } from "react";
import Lottie from "react-lottie";
import * as animationData from "../components/logos/animation-welcome.json";
import ediLogo from "../../edi-logo-transparent.png";

export const HomeTour = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // State for Joyride -- Tutorial
  const [{ run, steps }, setState] = useSetState({
    run: false,
    steps: [
      {
        content: (
          <h2 className="flex flex-col">
            <h2 className="flex text-center items-center justify-center">
              Ready to be the master of our platform?
            </h2>
            Let’s dive in!
            <small className="text-center text-gray-500 mt-2">
              This will only take ~20 seconds
            </small>{" "}
            {/* Small annotation */}
          </h2>
        ),
        placement: "center",
        target: "body",
        title: (
          <div className="flex justify-center items-center font-bold text-2xl mt-4">
            Welcome to{" "}
            <img className="w-auto h-8 ml-1 mt-[5px]" src={ediLogo} alt="Edi" />
          </div>
        ),
      },
      {
        content: (
          <h2>
            <div className="">Study Spaces are collections of videos.</div>{" "}
            <br />
            <div className="">
              <div className="mb-2">
                <strong>Public ones</strong> are pre-built and accessible to
                everyone.
              </div>
              <strong>Personal ones</strong> are accessible only to you when you{" "}
              <strong>sign up</strong>!
            </div>
          </h2>
        ),
        target: "#studySpace",
        placement: "bottom",
        title: (
          <div className="w-auto h-8 ml-1 mt-[5px] font-bold">Study Spaces</div>
        ),
      },
      {
        content: (
          <h2>Create your own tutor with a 
            {" "}<strong>YouTube link</strong> or {" "}<strong>Echo360 transcript</strong>. It’ll be right there in your 
            {" "}<strong>Personal Study Space</strong>.
          </h2>

        ),
        target: "#magic-button",
        title: (
          <div className="w-auto h-8 ml-1 mt-[5px] font-bold">
            Create Your Own Video Tutor
          </div>
        ),
      },
      {
        content: (
          <div className="flex flex-col items-center gap-5">
            <h2>Join our Discord to share your thoughts with us!</h2>
          </div>
        ),
        placement: "auto",
        target: "#discord-header-button",
        title: (
          <div className="flex justify-center items-center font-bold mt-[12px]">
            <Lottie
              options={defaultOptions}
              height={40}
              width={40}
              style={{ margin: 0 }}
            />
            Congrats! Home Page Tutorial Completed{" "}
            <Lottie
              options={defaultOptions}
              height={40}
              width={40}
              style={{ margin: 0 }}
            />
          </div>
        ),
        locale: { last: "Done" },
      },
    ],
  });

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setState({ run: false });
    }
  };

  // Check if this is the first visit to the page
  // If it is, show the tutorial
  useEffect(() => {
    const isHomeFirstVisit = localStorage.getItem("isHomeFirstVisit");
    if (!isHomeFirstVisit) {
      localStorage.setItem("isHomeFirstVisit", "true");
      setState({ run: true });
    }
  }, []);

  return (
    <Joyride
      callback={handleJoyrideCallback}
      continuous
      run={run}
      showSkipButton
      disableScrolling={true}
      steps={steps}
      styles={{
        options: {
          primaryColor: "#1976d2",
          zIndex: 1000,
        },
        tooltip: {
          padding: "20px", // Optional: Adjust padding for better content spacing
        },
        tooltipFooter: {
          marginTop: "5px", // Reduce the top margin for the entire footer section
        },
      }}
    />
  );
};
