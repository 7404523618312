import React, { useState, useRef, useEffect } from "react";
import { getAnswerInVideo, secondsToTime } from "../helper";
import ReactMarkdown from "react-markdown";
import styles from "./Chatbox.module.css"; // Importing CSS module
import { HiOutlineLightBulb } from "react-icons/hi";
import { quantum } from "ldrs";
import { useNavigate } from "react-router-dom";
import toastify from "../../../toastify";

quantum.register();

const Chatbox = ({
  _id,
  input,
  setInput,
  onSeek,
  currentSegment,
  close,
  setTimeFromChatbot,
  currentTime
}) => {
  const [messages, setMessages] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false); // State to track response generation
  const [isTextareaFocused, setIsTextareaFocused] = useState(false);
  const [selectedText, setSelectedText] = useState(null); // State for selected text
  const [actionMenuVisible, setActionMenuVisible] = useState(false); // State for showing action menu
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 }); // Position for action menu
  const menuRef = useRef(null); // Reference to the action menu

  const [config, setConfig] = useState(() => {
    // Getting the stored value from localStorage during initial state setup
    return localStorage.getItem("config") || "moderate";
  });
  const navigate = useNavigate();
  
  const isMobile = window.innerWidth < 980;

  const textAreaRef = useRef(null);
  const chatHistoryRef = useRef(null); // Ref for chat history div

  const demoChatbotUsageLeft = parseInt(localStorage.getItem("demoChatbotUsageLeft"), 10);
  const isDemoLimitReached = demoChatbotUsageLeft === 0;
  const isSignedIn = localStorage.getItem("token") !== "DEMO_TOKEN";

  const handleTextareaFocus = () => {
    setIsTextareaFocused(true);
  };

  const handleTextareaBlur = () => {
    setIsTextareaFocused(false);
  };

  useEffect(() => {
    const handleSelection = (event) => {
      const selection = window.getSelection();
      let text = selection.toString().trim(); // Capture only the selected portion of the text
  
      // Make sure we're not capturing extra text from <li> or other elements
      if (text.length > 0) {
        const selectedNode = selection.anchorNode;
        const isChatbotMessage = selectedNode?.parentElement?.closest('[data-chatbot-message="true"]');
  
        if (isChatbotMessage) {
          const rect = selection.getRangeAt(0).getBoundingClientRect();
          const menuHeight = 40; // Adjust based on your menu's height
          const adjustedTopPosition = rect.top - menuHeight - 10;
  
          setSelectedText(text); // Now only the selected text is stored
          setActionMenuVisible(true);
  
          // Ensure the menu is placed above the text selection
          setMenuPosition({
            top: adjustedTopPosition > 0 ? adjustedTopPosition : rect.bottom + 10, // Place above if enough space, otherwise below
            left: rect.left,
          });

        } else {
          setActionMenuVisible(false);
        }
      } else {
        setActionMenuVisible(false);
      }
    };
  
    document.addEventListener("mouseup", handleSelection); // For desktop
    document.addEventListener("touchend", handleSelection); // For mobile
  
    return () => {
      document.removeEventListener("mouseup", handleSelection);
      document.removeEventListener("touchend", handleSelection);
    };
  }, []);
  
  const handleActionClick = (action) => {
    console.log("saoiddhns")
    window.getSelection().removeAllRanges();
    // Logic to handle different actions based on the selected text
    switch (action) {
      case "example":
        sendMessage(`Give more examples of "${selectedText}".`);
        break;
      case "explanation":
        sendMessage(`Explain more about "${selectedText}".`);
        break;
      case "alternate":
        sendMessage(`Can you explain "${selectedText}" in another way?`);
        break;
      default:
        break;
    }
    setActionMenuVisible(false);
  };

  useEffect(() => {
    if (textAreaRef.current) {
      adjustTextAreaHeight(textAreaRef.current);
    }
  }, [input]);

  useEffect(() => {
    localStorage.setItem("config", config);
  }, [config]);

  const handleInputChange = (e) => {
    setInput(e.target.value);
    adjustTextAreaHeight(e.target);
  };

  const adjustTextAreaHeight = (textArea) => {
    textArea.style.height = "auto";
    textArea.style.height =
      textArea.scrollHeight < 100 ? `${textArea.scrollHeight + 2}px` : "100px";
  };

  const modifyLinkText = (text) => {
    const regex =
      /\[Video\]\((http:\/\/localhost:3000\/watch\/([^?]+)\?start=(\d+))\)/g;
    return text.replace(regex, (match, url, id, start) => {
      if (id === _id) {
        return ` [${secondsToTime(start)}](${url})`;
      }
      return match;
    });
  };

  const sendMessage = async (input) => {
    console.log(input)
    if (!input.trim() || isGenerating) return;
    setIsGenerating(true); // Indicate that a response is being generated
    const userMessage = { author: "user", text: input.trim() };
    setMessages((messages) => [...messages, userMessage]);
    setInput("");

    // Prepare chat history
    let history = [];
    if (messages.length > 0) {
      history = messages.map((msg) => ({
        role: msg.author.toLowerCase(),
        content: msg.text,
      }));
    }

    // Reset textarea height
    textAreaRef.current.style.height = "auto";

    // Add a temporary message indicating the system is generating an answer
    setMessages((messages) => [
      ...messages,
      { author: "assistant", text: "", isGenerating: true },
    ]);

    const token = localStorage.getItem("token");
    console.log(currentTime)
    const response = await getAnswerInVideo(
      history,
      input.trim(),
      _id,
      config,
      Math.floor(currentTime),
      token,
    );
    if (response && response.data.answer) {
      if (!isSignedIn && demoChatbotUsageLeft > 0) {
        localStorage.setItem("demoChatbotUsageLeft", demoChatbotUsageLeft - 1);
      }
      // Replace the temporary message with the actual answer
      const modifiedAnswer = modifyLinkText(response.data.answer);
      setMessages((messages) => [
        ...messages.slice(0, -1),
        {
          author: "assistant",
          text: modifiedAnswer,
          markdown: true,
          isNew: true,
        },
      ]);
    } else {
      // Handle errors or no response cases
      setMessages((messages) => [
        ...messages.slice(0, -1),
        {
          author: "assistant",
          text: "Unable to retrieve an answer, please try again later.",
          isNew: true,
        },
      ]);
    }
    setIsGenerating(false); // Reset the generating state
  };

  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [messages]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      sendMessage(input);
    }
  };

  const handleQuestionClick = (question) => {
    // console.log(question);
    setInput(question);
  };

  const handleQuestionClickAndSubmit = (question) => {
    sendMessage(question);
  };

  const codeBlockStyle = {
    backgroundColor: "#000",
    color: "#fff",
    padding: "10px",
    borderRadius: "5px",
    overflowX: "auto",
  };

  const handleLinkClick = (event, href) => {
    event.preventDefault();
    if (isMobile) {
      const startTime = parseInt(new URL(href).searchParams.get("start"), 10);
      setTimeFromChatbot(startTime);
      close();
      return;
    }

    const url = new URL(href, window.location.origin);
    const params = new URLSearchParams(url.search);
    if (href.includes(_id) && params.has("start")) {
      event.preventDefault();
      onSeek(params.get("start"));
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setActionMenuVisible(false); // Close menu if click is outside
        window.getSelection().removeAllRanges(); // Clear the text selection
      }
    };

    document.addEventListener("mousedown", handleClickOutside); // Listen for clicks outside
    document.addEventListener("touchstart", handleClickOutside); // Handle touch for mobile

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [menuRef]);

  useEffect(() => {
    const timeoutIds = [];
    messages.forEach((msg, index) => {
      if (msg.isNew) {
        const timeoutId = setTimeout(() => {
          setMessages((msgs) =>
            msgs.map((m, i) => (i === index ? { ...m, isNew: false } : m)),
          );
        }, 500);
        timeoutIds.push(timeoutId);
      }
    });
    return () => {
      timeoutIds.forEach((id) => clearTimeout(id));
    };
  }, [messages]);

  return (
    <div className={isMobile ? "" : styles.chatArea} id="chatbox">
      <div className={styles.chatComponentContainer}>
        <div className={styles.chatHistory} ref={chatHistoryRef}>
          {messages.length === 0 ? (
            <div
              style={{
                textAlign: "center",
                marginTop: "20px",
                color: "#9e9e9e",
              }}
            >
              {currentSegment && (
                <>
                  <div className="relative inline-block mb-2">
                    <HiOutlineLightBulb size={30} />
                  </div>
                  <h4 className={styles.headingStyle}>
                    {currentSegment.questions.length === 0 ? (
                      <>Ask me anything!</>
                    ) : (
                      <>You might want to ask ...</>
                    )}
                  </h4>
                  <ul style={{ listStyleType: "none" }}>
                    {currentSegment.questions
                      .slice(0, 3)
                      .map((question, index) => (
                        <li
                          key={index}
                          className={styles.questionStyle}
                          onClick={() => {
                            console.log(isSignedIn)
                            console.log(demoChatbotUsageLeft)
                            if (isSignedIn) {
                              handleQuestionClickAndSubmit(question)
                            } else if (demoChatbotUsageLeft <= 0) {
                              navigate("/signin")
                              toastify.info("You have used all your demo usages! Please sign in to continue.")
                            } else {
                              handleQuestionClickAndSubmit(question)
                            }
                          }}
                        >
                          {question}
                        </li>
                      ))}
                  </ul>

                  <div className="flex justify-center mt-6 mb-4">
                    <div
                      className="inline-flex border border-gray-300 rounded-md"
                      role="group"
                    >
                      <button
                        type="button"
                        className={`px-6 py-2 text-sm font-medium text-center rounded-l-lg ${config === "depth" ? "text-gray-900" : "text-gray-400"}`}
                        onClick={() => setConfig("depth")}
                      >
                        Depth
                      </button>
                      <button
                        type="button"
                        className={`px-6 py-2 text-sm font-medium text-center ${config === "moderate" ? "text-gray-900" : "text-gray-400"}`}
                        onClick={() => setConfig("moderate")}
                      >
                        Moderate
                      </button>
                      <button
                        type="button"
                        className={`px-6 py-2 text-sm font-medium text-center rounded-r-lg ${config === "broad" ? "text-gray-900" : "text-gray-400"}`}
                        onClick={() => setConfig("broad")}
                      >
                        Broad
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          ) : (
            <>
{actionMenuVisible && (
  <div
    ref={menuRef}
    className={`fixed bg-white border border-gray-300 rounded-lg shadow-lg p-2 z-50 transition-transform duration-200 ease-in-out transform ${
      window.innerHeight - menuPosition.top < 100 ? "translate-y-[-100%]" : "translate-y-0"
    }`}
    style={{
      top: `${menuPosition.top + (window.innerHeight - menuPosition.top < 100 ? -40 : window.scrollY + 20)}px`,
      left: `${menuPosition.left + window.scrollX}px`,
      transformOrigin: "top",
      userSelect: "none"
    }}
    onMouseDown={(e) => e.stopPropagation()} // Prevent menu clicks from closing the menu
    onTouchEnd={(e) => e.stopPropagation()}
  >
    <button
      onMouseDown={(e) => {
        e.preventDefault(); // Ensure mousedown works properly even with selection
        handleActionClick("example");
      }}
      onTouchEnd={(e) => {
        e.preventDefault();
        handleActionClick("example");
      }}
      className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
    >
      More Examples
    </button>
    <button
      onMouseDown={(e) => {
        e.preventDefault();
        handleActionClick("explanation");
      }}
      onTouchEnd={(e) => {
        e.preventDefault();
        handleActionClick("explanation");
      }}
      className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
    >
      More Explanation
    </button>
    <button
      onMouseDown={(e) => {
        e.preventDefault();
        handleActionClick("alternate");
      }}
      onTouchEnd={(e) => {
        e.preventDefault();
        handleActionClick("alternate");
      }}
      className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
    >
      Explain Another Way
    </button>
  </div>
)}


              {messages.map((msg, index) => (
                <div
                  key={index}
                  className={`${msg.author === "user" ? styles.messageUser : styles.messageBot}`}
                  data-chatbot-message={msg.author === "assistant" ? "true" : "false"}
                >
                  {msg.isGenerating ? (
                    <div className={styles.loadingIcon}>
                      <l-quantum
                        size="30"
                        speed="1.75"
                        color="black"
                      ></l-quantum>
                    </div>
                  ) : msg.markdown ? (
                    <div className={`${styles.newMessage}`}>
                      <ReactMarkdown
                        className="text-base leading-relaxed space-y-4"
                        components={{
                          a: ({ node, ...props }) => (
                            <a
                              {...props}
                              className="text-blue-500 underline cursor-pointer"
                              onClick={(event) =>
                                handleLinkClick(event, props.href)
                              }
                            >
                              {props.children}
                            </a>
                          ),
                          pre: ({ node, children, ...props }) => {
                            const hasCodeTag = React.Children.toArray(
                              children,
                            ).some((child) => child.type === "code");
                            if (hasCodeTag) {
                              // return <pre style={styles.codeBlockStyle}>{children}</pre>;
                              return (
                                <pre style={codeBlockStyle}>{children}</pre>
                              );
                            }
                            return <pre>{children}</pre>;
                          },
                          li: ({ node, ...props }) => (
                            <li className="mb-4" {...props} />
                          ),
                        }}
                      >
                        {msg.text}
                      </ReactMarkdown>
                    </div>
                  ) : (
                    <p>{msg.text}</p>
                  )}
                </div>
              ))}
              <div style={{ height: "18%" }}></div>{" "}
              {/* Spacer div for white space */}
            </>
          )}
        </div>

        <div
          style={{
            marginTop: "auto",
            marginBottom: "10px",
            position: "relative",
          }}
        >
          {isTextareaFocused &&
            !input &&
            messages.length !== 0 &&
            currentSegment &&
            !isGenerating && (
              <div className={styles.questionPrompt}>
                <ul className={styles.questionList}>
                  {currentSegment.questions
                    .slice(0, 3)
                    .map((question, index) => {
                      const reverseIndex =
                        currentSegment.questions.slice(0, 3).length - 1 - index;
                      return (
                        <li
                          key={index}
                          onMouseDown={() => {
                            console.log(isSignedIn)
                            console.log(demoChatbotUsageLeft)
                            if (isSignedIn) {
                              handleQuestionClick(question)
                            } else if (demoChatbotUsageLeft <= 0) {
                              navigate("/signin")
                              toastify.info("Please sign in first!")
                            } else {
                              handleQuestionClick(question)
                            }
                          }}
                          className={styles.questionStyle}
                          style={{
                            "--animation-delay": `${reverseIndex * 0.1}s`,
                          }}
                        >
                          {question}
                        </li>
                      );
                    })}
                </ul>
              </div>
            )}
          {!isSignedIn && (
            <div className="ml-2">
              <small className="text-gray-500 text-xs">
                You have <span className="text-blue-500 font-semibold">{demoChatbotUsageLeft}</span> demo uses left.
              </small>
              <button
                onClick={() => navigate('/signin')} 
                className="text-blue-500 underline hover:text-blue-700 text-xs ml-2"
              >
                Click here to sign in
              </button>
            </div>
          )}
          <div className={styles.inputContainer}>
            {/* Show demo usage left if not signed in */}
          
          <textarea
            ref={textAreaRef}
            value={input}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            onFocus={handleTextareaFocus}
            onBlur={handleTextareaBlur}
            className={styles.textArea}
            placeholder={isDemoLimitReached ? "Sign in to continue" : "Type your question here"}
            rows={1}
            disabled={isDemoLimitReached}
          />
          <button
            onClick={() => sendMessage(input)}
            className={styles.sendButton}
            disabled={!input.trim() || isGenerating || (isDemoLimitReached && !isSignedIn)}
          >
            {"Send"}
          </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chatbox;
