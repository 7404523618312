import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import { faComments } from "@fortawesome/free-regular-svg-icons";
import { Tabs } from "../../components/Tabs";

export const TimelineNavigator = ({
  segmentsLength,
  currentSegmentIndex,
  setIsTimelineModalOpen,
  setIsChatbotOpen,
  tabs,
  selected,
  setSelected,
  isExpanded,
  handlePrev,
  handleNext,
}) => {
  return (
    <div className="flex flex-col items-center justify-center gap-4 py-8">
      {/* Chatbot Button */}
      <button
        className="flex items-center gap-2 border border-gray-400 text-gray-700 p-2 rounded-lg px-4 cursor-pointer hover:bg-[#6196f3] hover:text-white transition-colors duration-300"
        onClick={() => setIsChatbotOpen(true)}
        id="videoChatbot"
      >
        <FontAwesomeIcon icon={faComments} />
        {!isExpanded && <span className="text-sm">Ask the Chatbot</span>}
      </button>
      {!isExpanded && (
        <>
          {/* Tabs */}
          <Tabs
            tabs={tabs}
            selected={selected}
            setSelected={setSelected}
            className={"flex justify-center gap-4"}
            id="styleChooser"
          />
        </>
      )}

      {/* Timeline Navigation */}
      <div
        className="flex gap-9 items-center text-[#6196f3] font-bold"
        id="videoTimelineNavigator"
      >
        {/* Previous Button */}
        <FontAwesomeIcon
          icon={faChevronLeft}
          className={`cursor-pointer ${
            currentSegmentIndex === 0 ? "text-gray-300 cursor-not-allowed" : ""
          }`}
          onClick={handlePrev}
        />

        {/* Timeline Button */}
        <div
          className="gap-3 flex items-center bg-[#6196f3] text-white p-2 rounded-lg px-4 cursor-pointer transition-transform hover:scale-105"
          onClick={() => setIsTimelineModalOpen(true)}
        >
          <FontAwesomeIcon icon={faCircleInfo} />
          <span className="text-sm">Choose Timeline</span>
        </div>

        {/* Next Button */}
        <FontAwesomeIcon
          icon={faChevronRight}
          className={`cursor-pointer ${
            currentSegmentIndex === segmentsLength - 1
              ? "text-gray-300 cursor-not-allowed"
              : ""
          }`}
          onClick={handleNext}
        />
      </div>
    </div>
  );
};
