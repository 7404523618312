import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const DiscordButton = () => {
  return (
    <a
      href="https://discord.gg/4nSVGv8A3g"
      title="Join our discord"
      rel="noopener noreferrer"
      className="items-center justify-center md:inline-flex px-3 py-2 text-xs font-bold text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:bg-blue-700"
      role="button"
      target="_blank"
      id="discord-header-button"
    >
      <FontAwesomeIcon icon={faDiscord} style={{ marginRight: "8px" }} />
      Join Our Discord
    </a>
  );
};
