import React, { useState, useEffect, useRef } from "react";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";

const Outline = ({
  onSeek,
  currentSegment,
  setCurrentSegmentIntro,
  timestamps,
}) => {
  const [currentSegmentTime, setCurrentSegmentTime] = useState(null);
  const [error, setError] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const segmentRefs = useRef({}); // Stores refs for all segments

  useEffect(() => {
    if (currentSegment && currentSegment.start) {
      setCurrentSegmentTime(currentSegment.start);
    }
  }, [currentSegment]);

  useEffect(() => {
    if (currentSegmentTime && segmentRefs.current[currentSegmentTime]) {
      segmentRefs.current[currentSegmentTime].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [currentSegmentTime, timestamps]);

  return (
    <div
      id="outline"
      style={{
        width: "100%",
        padding: "20px",
        border: "1px solid #d5d9e8",
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={(e) => {
          e.stopPropagation();
          setCollapsed(!collapsed);
        }}
      >
        <h2 style={{ color: "#1976d2", margin: 0, userSelect: "none" }}>
          Timeline
        </h2>
        <div>
          {collapsed ? (
            <FiChevronDown color="#1976d2" size="24px" />
          ) : (
            <FiChevronUp color="#1976d2" size="24px" />
          )}
        </div>
      </div>
      {!collapsed && (
        <div
          style={{
            maxHeight: "20dvh",
            marginTop: "10px",
            overflowY: "scroll",
          }}
        >
          {error && <p style={{ color: "red" }}>{error}</p>}
          {timestamps ? (
            <ul style={{ listStyleType: "none", padding: 0 }}>
              {timestamps.map((item, index) => (
                <li
                  key={index}
                  ref={(el) => (segmentRefs.current[item.timestamp] = el)}
                  style={{
                    marginBottom: "10px",
                    color:
                      item.timestamp === currentSegmentTime
                        ? "#0284C7"
                        : "#333",
                    fontSize: "16px",
                    fontWeight:
                      item.timestamp === currentSegmentTime ? "bold" : "normal",
                  }}
                >
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      onSeek(item.timestamp);
                    }}
                    className="cursor-pointer underline text-sky-600"
                  >
                    {item.timestamp}
                  </span>
                  {": "}
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      onSeek(item.timestamp);
                    }}
                    className={`cursor-pointer ${item.timestamp === currentSegmentTime ? "text-purple-[#9c27b0]" : "text-gray-600"}`}
                    // style={{
                    //   cursor: "pointer",
                    //   color:
                    //     item.timestamp === currentSegmentTime
                    //       ? "#9c27b0"
                    //       : "#555",
                    // }}
                  >
                    {item.timestamp === currentSegmentTime &&
                      setCurrentSegmentIntro(item.content)}
                    {item.content}
                  </span>
                </li>
              ))}
            </ul>
          ) : (
            <p>Loading timeline...</p>
          )}
        </div>
      )}
    </div>
  );
};

export default Outline;
