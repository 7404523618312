import Layout from "../Layout";

export const TermsAndConditions = () => {
  return (
    <Layout>
      <div className="max-w-5xl mx-auto px-6 py-12">
        <h1 className="text-3xl font-bold mb-6">Terms and Conditions</h1>

        <h2 className="text-2xl font-semibold mt-8 mb-4">1. Chat History</h2>
        <p className="mb-4">
          Edi does not store your chat history in our database. Conversations
          are processed in real-time and are retained locally. This applies to
          interactions with the chatbot.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          2. Responsible Use and Compliance
        </h2>
        <p className="mb-4">
          You agree that your use of Edi will comply with all applicable laws,
          regulations, and academic policies. Edi must not be used for any
          activity that violates university rules or policies, including the
          unauthorised use of AI tools for academic purposes. Edi holds no
          responsibility for any consequences arising from misuse of the
          platform.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          3. Acceptance of Terms
        </h2>
        <p className="mb-4">
          By using our platform, you agree to these terms and conditions, as
          well as our Privacy Policy. If you do not agree with any part of these
          terms, you must not use our services.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          4. Use of the Platform
        </h2>
        <p className="mb-4">
          You agree to use the platform for lawful purposes only. You may not
          use the platform in any way that breaches any applicable local,
          national, or international law or regulation.
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>
            You must not attempt to gain unauthorized access to the platform or
            its servers.
          </li>
          <li>
            You agree not to misuse the platform by knowingly introducing
            viruses or other malicious material.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4">5. User Accounts</h2>
        <p className="mb-4">
          When you create an account with us, you must provide accurate,
          complete, and up-to-date information. Failure to do so constitutes a
          breach of these terms, which may result in the termination of your
          account.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          6. Intellectual Property
        </h2>
        <p className="mb-4">
          The Edi platform includes third-party content, such as YouTube videos,
          that are embedded using the YouTube player. All intellectual property
          rights for these videos belong to their respective owners, and Edi
          does not claim any ownership over the video content.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">7. Termination</h2>
        <p className="mb-4">
          We reserve the right to suspend or terminate your access to the
          platform at our sole discretion, without notice, if we believe you
          have breached these terms or engaged in any illegal or inappropriate
          activity.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          8. Limitation of Liability
        </h2>
        <p className="mb-4">
          To the maximum extent permitted by law, Edi shall not be liable for
          any damages arising out of your use of the platform. This includes,
          without limitation, direct, indirect, incidental, or consequential
          damages.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          9. Changes to These Terms
        </h2>
        <p className="mb-4">
          We may update these terms and conditions from time to time. We will
          notify you of any significant changes, and your continued use of the
          platform will constitute acceptance of such updates.
        </p>

        <p className="mt-8">
          If you have any questions about these Terms and Conditions, please use
          the "Contact Us" section at the top, and we will get back to you
          shortly.
        </p>
      </div>
    </Layout>
  );
};
