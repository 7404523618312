import React from "react";
import styles from "./Popup.module.css";

const Popup = ({
  message,
  primaryLabel,
  secondaryLabel,
  onPrimaryAction,
  onSecondaryAction,
}) => {
  return (
    <div className={styles.popupOverlay}>
      <div className={styles.popupContainer}>
        <p className={styles.popupMessage}>{message}</p>
        <div className={styles.popupActions}>
          <button className={styles.primaryButton} onClick={onPrimaryAction}>
            {primaryLabel}
          </button>
          <button
            className={styles.secondaryButton}
            onClick={onSecondaryAction}
          >
            {secondaryLabel}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Popup;
