import React, { useState, useEffect } from "react";
// import Layout from "../Layout";
import styles from "./login.module.css";
import Header from "../header/header";
import layoutStyles from "../Layout.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
// import { GoogleOAuthProvider, GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from "@react-oauth/google";
import { ParticlesComponent } from "../components/Particles";
import { useNavigate } from "react-router-dom";
import { googleLoginFetch, signupFetch } from "./helper";
import toastify from "../../toastify";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom"; // Updated import

import { useContext } from "react";
import { CreditsContext } from "../header/CreditsContext";

const MemoizedParticlesComponent = React.memo(ParticlesComponent);

export const SignUp = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [referralCode, setReferralCode] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const { fetchCredits } = useContext(CreditsContext);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const ref = params.get("ref");
    if (ref) {
      setReferralCode(ref);
      // Optionally, store it in localStorage or context if needed elsewhere
      // localStorage.setItem("referralCode", ref);
    }
  }, [location.search]);

  const googleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      setIsProcessing(true);
      // // console.log("Google Login Success:", response);
      try {
        const res = await googleLoginFetch(response.access_token, referralCode);
        // console.log(res);
        if (res.status === 200) {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("name", res.data.name);
          const redirectPage = localStorage.getItem("beforeSignInPage") || "/";
          localStorage.removeItem("beforeSignInPage");
          toastify.custom(`Welcome ${res.data.name}!`);
          navigate(redirectPage);
          // console.log("google login done");

          fetchCredits(res.data.token); // update number of credits on header
        } else {
          toastify.error(res.data.detail);
        }
      } catch (err) {
        toastify.error("Google sign up failed! Please try again.");
      } finally {
        setIsProcessing(false); // Reset processing state after completion
      }
    },
    onError: (errorResponse) => {
      toastify.error("Google sign up failed! Please try again.");
      setIsProcessing(false);
    },
  });

  const handleSignupClick = () => {
    const isSignIn =
      localStorage.getItem("token") !== "DEMO_TOKEN" &&
      localStorage.getItem("token") !== null;
    if (isSignIn) {
      toastify.error("You already sign in");
    } else {
      setIsProcessing(true);
      googleLogin();
    }
  };

  const handleSignUp = async (e) => {
    setIsProcessing(true);

    const isSignIn = localStorage.getItem("token") !== "DEMO_TOKEN";

    if (isSignIn) {
      toastify.error("You already sign in!");
      setIsProcessing(false);
    } else {
      e.preventDefault();

      try {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // Regular expression to validate password format
        // Password must be at least 8 characters, include at least one uppercase, one lowercase, one number, and one special character
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/;
        // Validate email format
        if (!emailRegex.test(email)) {
          toastify.error("Invalid email format!");
          return; // Stop execution if email is invalid
        }

        // Validate password format
        if (!passwordRegex.test(password)) {
          toastify.error(
            "Password must be at least 8 characters long, include at least one uppercase letter, one lowercase letter, one number.",
          );
          return; // Stop execution if password is invalid
        }

        const res = await signupFetch(email, name, password, referralCode);
        if (res.status === 200) {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("name", res.data.name);
          const redirectPage = localStorage.getItem("beforeSignInPage") || "/";
          localStorage.removeItem("beforeSignInPage");
          if (referralCode.length === 0) {
            toastify.custom(`Welcome ${res.data.name}!`);
          } else {
            toastify.custom(
              `Welcome ${res.data.name}! You and your friend have earned 5 bonus credits each. Thanks for joining us!🎉`,
            );
          }
          navigate(redirectPage);

          fetchCredits(res.data.token); // update number of credits on header
        } else {
          console.error("Signup failed:", res);
          toastify.error(res.data.detail);
        }
      } catch (err) {
        console.error("Error during sign up: ", err);
        toastify.error(
          "An error occurred while signing you up! Please try again.",
        );
      } finally {
        setIsProcessing(false); // Reset processing state after completion
      }
    }
  };

  return (
    <div className={layoutStyles.Layout}>
      <Header />
      <MemoizedParticlesComponent />
      <div className={styles["login-container"]}>
        <div className="flex justify-between items-center w-full">
          <h2 className={styles["signup-title"]}>Sign up</h2>
          <span
            className={styles["signin-prompt"]}
            onClick={() => navigate("/signin")}
          >
            {" "}
            I already had an account
          </span>
        </div>

        <form onSubmit={handleSignUp}>
          <input
            type="text"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <div className={styles["password-container"]}>
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button
              type="button"
              className={styles["toggle-password"]}
              onClick={togglePasswordVisibility}
            >
              <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
            </button>
          </div>
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <button className={styles["login-button"]} type="submit">
            {isProcessing ? "Signing you up..." : "Agree and Sign up"}
          </button>
        </form>
        <div className={styles.terms}>
          By signing in, you agree to the{" "}
          <Link to="/terms">Terms and Conditions</Link> and{" "}
          <Link to="/privacy">Privacy Policy</Link>.
        </div>
        <div
          className={styles["social-login"]}
          onClick={() => handleSignupClick()}
        >
          <div className={styles["social-button"]}>
            <img
              class="w-5 h-5"
              src="https://www.svgrepo.com/show/475656/google-color.svg"
              loading="lazy"
              alt="google logo"
            />
            <span className="ml-1">Sign up with Google</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
