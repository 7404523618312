import { BrowserRouter, Routes, Route } from "react-router-dom";
import Watch from "./pages/watch-page/Watch";
import { Studyspace } from "./pages/studyspace-page/Studyspace";
import { Home } from "./pages/home-page/Home";
import Test from "./pages/test-page/Test";
import { About } from "./pages/about-page/about";
import SignUp from "./pages/login-page/SignUp";
import SignIn from "./pages/login-page/SignIn";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ToastContainer } from "react-toastify";
import { Bounce } from "react-toastify";
import { VideoModalProvider } from "./pages/VideoModalContext";
import { Privacy } from "./pages/Privacy/Privacy";
import { TermsAndConditions } from "./pages/Terms/Terms";
import { useEffect } from "react";
import { useState } from "react";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { CreditsProvider } from "./pages/header/CreditsContext";
import { CreditModalProvider } from "./pages/header/CreditModalContext";

const clientId =
  process.env.REACT_APP_GOOGLE_CLIENT_ID || process.env.GOOGLE_CLIENT_ID;

function App() {
  const [isTokenInitialized, setIsTokenInitialized] = useState(false); // make sure token is not null at any time

  useEffect(() => {
    // Check if the token exists in localStorage
    const token = localStorage.getItem("token");

    // If the token doesn't exist or is an empty string, set the DEMO_TOKEN
    if (!token || token === "") {
      localStorage.setItem("token", "DEMO_TOKEN");
    }

    const demoChatbotUsageLeft = localStorage.getItem("demoChatbotUsageLeft");

    if (!demoChatbotUsageLeft) {
      localStorage.setItem("demoChatbotUsageLeft", 20);
    }

    // After setting the token, mark it as initialized
    setIsTokenInitialized(true);
  }, []);

  // Render nothing until the token is initialized to avoid initial moment with null token
  if (!isTokenInitialized) {
    return null; // or some loading component
  }

  return (
    <div className="App">
      <SpeedInsights />
      <Analytics />
      <CreditModalProvider>
        <CreditsProvider>
          <VideoModalProvider>
            <GoogleOAuthProvider clientId={clientId}>
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/test" element={<Test />} />
                  <Route path="/Watch/:id" element={<Watch />} />
                  <Route
                    path="/studyspace/:studyspaceId"
                    element={<Studyspace />}
                  />
                  <Route path="/about" element={<About />} />
                  <Route path="/signup" element={<SignUp />} />
                  <Route path="/signin" element={<SignIn />} />
                  <Route path="/privacy" element={<Privacy />} />
                  <Route path="/terms" element={<TermsAndConditions />} />
                </Routes>
              </BrowserRouter>
            </GoogleOAuthProvider>
          </VideoModalProvider>
        </CreditsProvider>
      </CreditModalProvider>
      <ToastContainer
        stacked
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
    </div>
  );
}

export default App;
