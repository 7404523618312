import Layout from "../Layout";
import { AwesomeButton } from "react-awesome-button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import "react-awesome-button/dist/styles.css";
import "./about.css";
import { FaCloud, FaUsers } from "react-icons/fa"; // Cloud for dream, Users for community
import ediLogo from "../../edi-logo-transparent.png";

import { VaraText } from "./VaraText";

const handleClick = () => {
  window.open("https://discord.gg/4nSVGv8A3g", "_blank");
};

export const About = () => {
  return (
    <Layout>
      <div className="mx-auto max-w-4xl p-6">
        <h2 className="text-center text-2xl font-semibold text-gray-800 mt-0">
          <VaraText text="Building More Than a Tool. A Home for Learners." />
        </h2>

        <h1 className="text-3xl font-bold text-center mb-5 mt-6 text-gray-800 md:mb-8">
          Our Vision
        </h1>
        <p className="text-lg text-gray-700 mb-6 md:mb-8">
          We’re a team of students from UNSW and USYD, but we share a Dream! To build a
          home for learners with{" "}
          <img src={ediLogo} alt="EDI Logo" className="inline-block h-6 mx-0" />
          
        </p>

        <p className="text-lg text-gray-700 mb-6 md:mb-8">
          Learning can feel lonely, but it doesn’t have to. By blending AI with
          education, we want to create a space where students feel supported,
          engaged, and inspired to grow at our own pace.
        </p>

        <p className="text-lg text-gray-700 mb-7 md:mb-8">
          Together, we’re building more than just a tool — we’re shaping a
          home where
          learning becomes personal, empowering, and accessible for everyone.
        </p>

        {/* <p className="text-lg text-gray-700 mb-6 text-center">Join us!</p> */}
        <div className="flex justify-center gap-6 mb-10">
          <AwesomeButton type="primary" onPress={handleClick}>
            <FontAwesomeIcon icon={faDiscord} style={{ marginRight: "8px" }} />
            Join Our Discord
          </AwesomeButton>
        </div>
      </div>
    </Layout>
  );
};
