import React, { useState } from "react";
import styles from "./promptEarnCreditModal.module.css"; // Import your styles
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons"; // Import close icon
import { AwesomeButton } from "react-awesome-button";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import toastify from "../../toastify";
import { refLinkFetch } from "../login-page/helper";
import { useNavigate } from "react-router-dom";
import { feedbackFetch } from "../login-page/helper";
import { useContext } from "react";
import { CreditsContext } from "./CreditsContext";

const PromptEarnCreditModal = ({ setCreditModalOpen }) => {
  const [feedback, setFeedback] = useState("");
  const [promoCode, setPromoCode] = useState(""); // State for promo code
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [linkCopied, setLinkCopied] = useState(false);
  const [feedbackSubmitting, setFeedbackSubmitting] = useState(false);
  const { fetchCredits } = useContext(CreditsContext);
  const [referralLoading, setReferralLoading] = useState(false);

  const getRefLink = async () => {
    setReferralLoading(true);

    const res = await refLinkFetch(token);
    // console.log(res);
    if (res.status === 200) {
      navigator.clipboard.writeText(res.data.link);
      toastify.custom(
        "Referral link copied to your clipboard! Share it with your friends!",
      );
      setLinkCopied(true);
    } else if (res.status === 401) {
      navigate("/signin");
      toastify.error("Please sign in first!");
    } else {
      toastify.error(res.data.detail);
    }

    setReferralLoading(false);
  };

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };

  const handlePromoCodeChange = (e) => {
    setPromoCode(e.target.value);
  };

  const submitPromoCode = () => {
    toastify.error("Please enter valid promo code!");
  };

  const handleFeedbackSubmit = async () => {
    setFeedbackSubmitting(true);
    const wordCount = feedback.trim().split(/\s+/).length;

    if (wordCount < 10) {
      toastify.error("Feedback must contain at least 10 words!");
    } else {
      try {
        // Proceed with submitting the feedback
        const res = await feedbackFetch(token, feedback);

        // Handle different response status codes
        if (res.status === 200) {
          toastify.custom(res.data.message);
          // Optionally, clear the feedback input
          setFeedback("");
          fetchCredits();
        } else {
          toastify.error(res.data.detail);
        }
      } catch (error) {
        console.error("Error submitting feedback:", error);
        toastify.error(
          "An error occurred while submitting feedback! Please try again later.",
        );
      }
    }
    setFeedbackSubmitting(false);
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        {/* Close Button (X) */}
        <button
          className={styles.closeButton}
          onClick={() => setCreditModalOpen(false)}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>

        <h2 className={styles.modalTitle}>
          <i>Earn Free Credits with These Simple Ways!</i>
        </h2>
        <div className={styles.modalBody}>
          {/* Left Section: Feedback (Way 1) */}
          <div className={styles.modalSection}>
            <h3 className={styles.modalSectionTitle}>
              Way 1: Give Us Feedback
            </h3>
            <p>We need your feedback! Submit feedback and earn 5 credits.</p>
            <textarea
              value={feedback}
              onChange={handleFeedbackChange}
              placeholder="E.g., 'I love the ... features, but it would be great if ....'"
              rows="4"
              className={styles.urlInputWay1}
            />
            <div className="flex justify-center">
              <button
                className={styles.modalButton}
                onClick={handleFeedbackSubmit}
                disabled={feedbackSubmitting}
              >
                {feedbackSubmitting ? "Submitting..." : "Submit Feedback"}
              </button>
            </div>
          </div>

          {/* Right Section: Way 2 and Way 3 */}
          <div className={styles.modalSectionRight}>
            {/* Way 2: Referral */}
            <div className={styles.way2And3Section}>
              <h3 className={styles.modalSectionTitle}>
                Way 2: Share with Friends
              </h3>
              <p>
                Share this link with your friend. When they register, both of
                you get 5 credits!
              </p>
              <button
                className={styles.modalButton}
                onClick={getRefLink}
                disabled={linkCopied || referralLoading}
              >
                {referralLoading
                  ? "Copying Referral Link..."
                  : linkCopied
                    ? "Referral Link Copied"
                    : "Copy Referral Link"}
              </button>
            </div>

            {/* Way 3: Promo Code */}
            <div className={styles.way2And3Section}>
              <h3 className={styles.modalSectionTitle}>
                Way 3: Enter Promo Code from Discord
              </h3>
              <p>
                <a
                  href="https://discord.gg/4nSVGv8A3g"
                  title="Join our discord"
                  rel="noopener noreferrer"
                  className="items-center justify-center px-3 py-2 text-xs font-bold mr-2 text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-md inline-flex hover:bg-blue-700 focus:bg-blue-700"
                  role="button"
                  target="_blank"
                >
                  <FontAwesomeIcon
                    icon={faDiscord}
                    style={{ marginRight: "8px" }}
                  />
                  Join Our Discord
                </a>{" "}
                and receive exclusive promo codes to earn free credits.
              </p>
              <input
                type="text"
                value={promoCode}
                onChange={handlePromoCodeChange}
                placeholder="Enter your promo code"
                className={styles.urlInput}
              />
              <button className={styles.modalButton} onClick={submitPromoCode}>
                Submit Promo Code
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromptEarnCreditModal;
