import React, { createContext, useState, useEffect } from "react";
import { creditsFetch } from "../login-page/helper";
import toastify from "../../toastify";

export const CreditsContext = createContext();

export const CreditsProvider = ({ children }) => {
  const [credits, setCredits] = useState(null);
  let token = localStorage.getItem("token");

  const fetchCredits = async (token_passed_in = null) => {
    if (token_passed_in) {
      token = token_passed_in;
    }

    try {
      if (token && token !== "DEMO_TOKEN") {
        const response = await creditsFetch(token);
        if (response.status === 200) {
          setCredits(response.data.credits);
        } else {
          setCredits(null);
        }
      }
    } catch (error) {
      toastify.error("Failed to fetch credits!");
      setCredits(null); // Don't display credits if fetching fails
    }
  };

  useEffect(() => {
    fetchCredits();
  }, [token]);

  return (
    <CreditsContext.Provider value={{ credits, setCredits, fetchCredits }}>
      {children}
    </CreditsContext.Provider>
  );
};
