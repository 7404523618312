import { useState, useContext, useRef } from "react";
import { VideoModalContext } from "./VideoModalContext";
import { useNavigate } from "react-router-dom";
import { CreditsContext } from "./header/CreditsContext";
import toastify from "../toastify";
import { extractYouTubeID } from "./watch-page/helper";
import { FaYoutube } from "react-icons/fa"; // Import YouTube icon from react-icons
import { MdSchool } from "react-icons/md"; // Example: Echo360 icon can be a school icon
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./generateVideoModal.module.css";
import echoGif from './Echo360_gif_zoom_in.gif'; // Adjust the path if necessary

export const GenerateVideoModal = ({
  setShowPopup,
  pollingCounterRef,
  fetchVideoList,
}) => {
  const { fetchCredits } = useContext(CreditsContext);
  const { generateVideoOpen, toggleGenerateVideo } =
    useContext(VideoModalContext);
  const navigate = useNavigate();

  const [youtubeURL, setYoutubeURL] = useState("");
  const [transcriptFile, setTranscriptFile] = useState(null);
  const [loadingURL, setLoadingURL] = useState(false); // Loading state for YouTube URL
  const [loadingTranscript, setLoadingTranscript] = useState(false); // Loading state for transcript
  const [isTooltipOpen, setIsTooltipOpen] = useState(false); // State to control tooltip

  const token = localStorage.getItem("token");

  const videoServerUrl =
    process.env.REACT_APP_VIDEO_PROCESS_SERVER_URL ||
    process.env.VIDEO_PROCESS_SERVER_URL;

  const fileInputRef = useRef(null); // Reference for the hidden file input

  // Toggle tooltip visibility
  const toggleTooltip = () => {
    setIsTooltipOpen(!isTooltipOpen);
  };

  const handleURLChange = (event) => {
    setYoutubeURL(event.target.value);
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file && file.name.endsWith(".vtt")) {
      setTranscriptFile(file);
      //toastify.success("Transcript file successfully added!");
    } else {
      toastify.error("Please upload a valid VTT file.");
    }
  };

  const handleFileClick = () => {
    fileInputRef.current.click(); // Simulate a click on the hidden file input when the area is clicked
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.name.endsWith(".vtt")) {
      setTranscriptFile(file);
      // toastify.success("Transcript file successfully added!");
    } else {
      toastify.error("Please upload a valid VTT file.");
    }
  };

  const handleFileUpload = async () => {
    if (!transcriptFile) {
      toastify.error("No file selected.");
      return;
    }
    try {
      const formData = new FormData();

      const title = transcriptFile.name.replace(/\.vtt$/, "");

      formData.append("file", transcriptFile);
      formData.append("title", title); // Use the file name without .vtt as the title
      formData.append("description", "");

      setLoadingTranscript(true); // Start loading for transcript upload
      const response = await fetch(`${videoServerUrl}/submit_transcript`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const data = await response.json();

      setLoadingTranscript(false); // Stop loading for transcript upload

      if (response.status === 202 || response.status === 200) {
        toastify.success("Transcript uploaded successfully!");
        fetchVideoList();
        fetchCredits();

        if (pollingCounterRef) {
          pollingCounterRef.current = 0;
        }

        if (data.personal_studyspace_id) {
          navigate(`/studyspace/${data.personal_studyspace_id}`);
          toggleGenerateVideo();
          fetchVideoList();
        }
      } else if (response.status === 401) {
        localStorage.setItem("token", "DEMO_TOKEN"); // logout process
        navigate("/signin");
      } else {
        toastify.error(`${data.detail}`);
      }
    } catch (error) {
      setLoadingTranscript(false); // Stop loading on error
      toastify.error(
        "An error occurred during file upload! Please try again later.",
      );
    }
  };

  const handleSubmitURL = async () => {
    if (!token) {
      toastify.error("Please sign in first!");
      navigate("/signin");
      return;
    }

    const videoId = extractYouTubeID(youtubeURL);
    if (!videoId) {
      toastify.error("Please enter a valid YouTube URL!");
      return;
    }
    try {
      setLoadingURL(true); // Start loading for URL submission
      const response = await fetch(`${videoServerUrl}/submit_video`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ video_id: videoId }),
      });

      const data = await response.json();
      setLoadingURL(false); // Stop loading for URL submission

      if (response.status === 202 || response.status === 200) {
        toastify.custom(
          `Video successfully submitted! It will appear in your personal studyspace.`,
        );
        fetchCredits();
        setYoutubeURL("");
        if (pollingCounterRef) {
          pollingCounterRef.current = 0;
        }

        if (data.personal_studyspace_id) {
          navigate(`/studyspace/${data.personal_studyspace_id}`);
          toggleGenerateVideo();
          fetchVideoList();
        }
      } else if (response.status === 401) {
        localStorage.setItem("token", "DEMO_TOKEN"); // logout process
        navigate("/signin");
      } else {
        toastify.error(`${data.detail}`);
      }
    } catch (err) {
      setLoadingURL(false); // Stop loading on error
      toastify.error(`Failed to submit video! Please try again later.`);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className={`${styles.animated} bg-white rounded-lg shadow-lg max-w-lg w-full px-8 pt-8 pb-7 mx-4 md:mx-0 relative`}>
        <button
          className="absolute top-2 right-4 text-gray-400 hover:text-gray-600 focus:outline-none"
          onClick={toggleGenerateVideo}
        >
          ✕
        </button>
        <h2 className="text-2xl font-semibold text-gray-900 text-center mb-6">
          Submit Video or Transcript
        </h2>

        {/* YouTube URL Input Section */}
        <div className="mb-6">
          <h3 className="text-lg font-medium text-gray-700 mb-2 flex items-center">
            <FaYoutube className="text-red-600 mr-2" /> Submit YouTube URL
          </h3>
          <input
            type="text"
            placeholder="Enter YouTube URL"
            value={youtubeURL}
            onChange={handleURLChange}
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#42A5F5]"
            disabled={loadingURL} // Disable input when loading for URL submission
          />
          <button
            className={`w-full bg-[#6196f3] text-white font-semibold py-2 rounded-lg mt-4 hover:bg-[#1E88E5] focus:outline-none ${loadingURL ? "opacity-50 cursor-not-allowed" : ""}`}
            onClick={handleSubmitURL}
            disabled={loadingURL} // Disable button when loading for URL submission
          >
            {loadingURL ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              "Submit YouTube URL"
            )}
          </button>
        </div>

        {/* Separator with 'or' */}
        <div className="relative my-6 flex items-center">
          <div className="flex-grow border-t border-gray-300"></div>
          <span className="mx-2 text-sm text-gray-500">or</span>
          <div className="flex-grow border-t border-gray-300"></div>
        </div>

        {/* Transcript File Upload Section */}
        <div className="mb-4">
          <h3 className="text-lg font-medium text-gray-700 mb-2 flex items-center">
            <MdSchool className="text-blue-500 mr-2" /> Upload Echo360
            Transcript
          </h3>

          
          <div
            onClick={handleFileClick} // Clicking anywhere on the box triggers file select
            onDrop={handleFileDrop}
            onDragOver={(e) => e.preventDefault()}
            className="w-full h-28 border-2 border-dashed border-gray-400 rounded-lg flex justify-center items-center text-gray-600 hover:cursor-pointer hover:bg-gray-100"
          >
            {transcriptFile ? (
              <p className="text-center">{transcriptFile.name} uploaded.</p>
            ) : (
              <p className="text-center px-3">
                Drag & drop your .vtt transcript here, or click to upload
              </p>
            )}
          </div>

          {/* Hidden file input */}
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
            accept=".vtt"
          />

          <button
            className={`w-full bg-[#6196f3] text-white font-semibold py-2 rounded-lg mt-4 hover:bg-[#1E88E5] focus:outline-none ${loadingTranscript ? "opacity-50 cursor-not-allowed" : ""}`}
            onClick={handleFileUpload}
            disabled={loadingTranscript} // Disable button when loading for transcript upload
          >
            {loadingTranscript ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              "Upload Transcript"
            )}
          </button>

          {/* Instruction GIF to show how to export transcript */}
          <div className="mb-4">
            <p className="text-sm text-gray-500 text-center mt-3 underline cursor-pointer relative" onClick={toggleTooltip}>
              Don’t know how to download the transcript from Echo360? 
              {isTooltipOpen && <span className="tooltip absolute left-1/2 transform -translate-x-1/2 bottom-12 bg-gray-100 border border-gray-300 p-2 rounded-md shadow-lg w-[100vw] md:w-[800px] h-auto group-hover:block">
                <button
                  className="absolute top-1 right-2 text-white bg-gray-600 rounded-full w-6 h-6 flex justify-center items-center hover:bg-gray-700 focus:outline-none"
                  onClick={toggleTooltip}
                >
                  ✕
                </button>
                <img
                  src={echoGif}
                  alt="How to download transcript from Echo360"
                  className="w-full"
                />
              </span>}
            </p>
          </div>

        </div>
      </div>
    </div>
  );
};
