import { motion } from "framer-motion";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Avatar } from "./avatar";
import { MenuButton } from "./hamburger-button";
import { useEffect } from "react";

export const Menu = ({
  logOut,
  isLoggedIn,
  signIn,
  isModalOpen,
  setIsModalOpen,
}) => {
  const [open, setOpen] = useState(false);
  const name = localStorage.getItem("name");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 980);
  const [isProcessing, setIsProcessing] = useState(false); // New state for processing

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 980);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (name === null) {
    localStorage.setItem("name", "Not Logged In");
  }

  const handleLogout = () => {
    if (isProcessing) return; // Prevent further clicks

    setIsProcessing(true); // Set processing state to true
    logOut(); // Perform the logout action

    // Optionally, reset the state after logout finishes, depending on how your logout works
    setTimeout(() => {
      setIsProcessing(false); // Reset processing state after logout completes
    }, 1000); // Adjust the timeout duration based on your logout flow
  };

  return (
    <div>
      <motion.div
        animate={open ? "open" : "closed"}
        className="relative z-50"
        id="menu-button"
      >
        <MenuButton onClick={() => setOpen(!open)} />
        <Avatar name={name} onClick={() => setOpen(!open)} />
        <motion.ul
          initial={wrapperVariants.closed}
          variants={wrapperVariants}
          style={{ originY: "top", translateX: "-50%" }}
          className="flex flex-col gap-2 p-2 rounded-lg bg-white shadow-xl absolute top-[120%] left-[-70%] w-[130px] overflow-hidden z-20"
        >
          {isMobile && (
            <>
              <Option setOpen={setOpen} link="/" text="Study Space" />
              <Option setOpen={setOpen} link="/about" text="About Us" />
              <motion.li
                variants={itemVariants}
                onClick={() => {
                  setOpen(!open);
                  setIsModalOpen(true);
                }}
                className="flex items-center gap-2 w-full p-2 text-xs whitespace-nowrap rounded-md hover:bg-gray-200 transition-opacity cursor-pointer"
              >
                Contact Us
              </motion.li>
            </>
          )}

          <motion.li
            variants={itemVariants}
            onClick={isLoggedIn ? handleLogout : signIn}
            className={`flex items-center gap-2 w-full p-2 text-xs whitespace-nowrap rounded-md hover:bg-gray-200 transition-opacity cursor-pointer ${isProcessing ? "opacity-50 cursor-not-allowed" : ""}`} // Add visual feedback
          >
            <span>{isLoggedIn ? "Sign Out" : "Sign In"}</span>
          </motion.li>
        </motion.ul>
      </motion.div>
    </div>
  );
};

const Option = ({ text, link, setOpen }) => {
  return (
    <Link to={link} title="">
      <motion.li
        variants={itemVariants}
        onClick={() => setOpen(false)}
        className="flex items-center gap-2 w-full p-2 text-xs font-medium whitespace-nowrap rounded-md hover:bg-indigo-100 text-slate-700 hover:text-indigo-500 transition-colors cursor-pointer lg:hidden"
      >
        <span>{text}</span>
      </motion.li>
    </Link>
  );
};

const wrapperVariants = {
  open: {
    scaleY: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
  closed: {
    scaleY: 0,
    transition: {
      when: "afterChildren",
      staggerChildren: 0.1,
    },
  },
};

const itemVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: {
      when: "beforeChildren",
    },
  },
  closed: {
    opacity: 0,
    y: -15,
    transition: {
      when: "afterChildren",
    },
  },
};
