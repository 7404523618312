export const Switch = ({ toggleLightsOn, lightsOn }) => {
  return (
    <div class="switchScope">
      <div class="col">
        <label>
          <span class="switch">
            <input
              class="switch__input"
              type="checkbox"
              role="switch"
              onClick={toggleLightsOn}
              checked={lightsOn}
            />
            <span class="switch__surface">
              <span class="switch__surface-glare"></span>
            </span>
            <span class="switch__inner-shadow"></span>
            <span class="switch__inner">
              <span class="switch__inner-glare"></span>
            </span>
            <span class="switch__rocker-shadow"></span>
            <span class="switch__rocker-sides">
              <span class="switch__rocker-sides-glare"></span>
            </span>
            <span class="switch__rocker">
              <span class="switch__rocker-glare"></span>
            </span>
            <span class="switch__light">
              <span class="switch__light-inner"></span>
            </span>
          </span>
          <span class="sr">Light Switch</span>
        </label>
      </div>
    </div>
  );
};
