import styles from "./header.module.css";
import { FaWandMagicSparkles } from "react-icons/fa6";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

export const MagicButton = ({
  generateVideoOpen = false,
  toggleGenerateVideo,
}) => {
  return (
    <button
      className={`${styles["magic-button"]} ${styles["glowing"]} hover:bg-gray-100 focus:bg-gray-100`} // Conditionally add the 'glowing' class
      onClick={toggleGenerateVideo}
      id="magic-button"
    >
      <FontAwesomeIcon icon={faUpload} />
    </button>
  );
};
